export const getChosenFilters = (filters) => {
    const filtersArr = [];
    const filterFields = {
        sex: "Пол",
        years: "Возраст",
        category: "Группы риска",
    };

    if (filters.sex) {
        const sex = filters.sex === "1" ? "Мужской" : "Женский";
        filtersArr.push(`${filterFields.sex}: ${sex}`);
    }

    if (filters.years) {
        filtersArr.push(`${filterFields.years}: ${filters.years}`);
    }

    if (filters.category) {
        filtersArr.push(`${filterFields.category}: ${filters.categoryName}`);
    }

    return filtersArr.length ? filtersArr.join(", ") : "";
};

export const getChosenConditions = (condition, pupilsList) => {
    if (condition) {
        return condition;
    }

    const uniqConditions = []

    for (const pupil of pupilsList) {
        if (pupil.conditions) {
            for (const condition of pupil.conditions) {
                if (!uniqConditions.includes(condition)) {
                    uniqConditions.push(condition)
                }
            }
        }
    }

    return uniqConditions.join(",");
};
