import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import { showAlert, hideAlert } from "redux/actions/setAppActions";
import { getConditions } from "redux/actions/setConditionActions";
import { getTestsBiPupilId } from "redux/actions/setTestActions";
import { declinations } from "helpers/declinations";
import { Info } from "components/Info";
import { Loader } from "components/Loader";
import { Layout } from "components/Layout";

/**
 * @api {get} /tests?pupilId=pupilId&schoolId=schoolId&classId=classId список тестов ученика
 * @apiGroup tests
 * @apiName список тестов ученика
 *
 * @apiQuery {String} pupilId ID ученика
 * @apiQuery {String} schoolId ID школы
 * @apiQuery {String} classId ID класса
 *
 * @apiDescription <p>Страница со списком тестов ученика</p>
 * <p>Компонент <code>TestsPage.js</code> <a href="../client/src/pages/TestsPage.js">ссылка на файл</a></p>
 */

export const TestsPage = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [info, setInfo] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const { loading } = useSelector((state) => state.app);
    const { role } = useSelector((state) => state.auth);
    const { tests, conditions } = useSelector((state) => state.test);
    const paramId = useParams().id;
    const [isUrlChecked, setIsUrlChecked] = useState(false);

    function goBack() {
        if (role === "pupil") {
            window.location.href = "/dashboard";
        } else {
            history.goBack();
        }
    }

    useEffect(() => {
        let iter;
        for (let i = 0; i < tests.length; i++) {
            if (tests[i].conditionId === paramId) {
                iter = i;
            }
        }
        if (location.pathname.includes("instruction") && iter) {
            setInfo({
                id: tests[iter]._id,
                name: tests[iter].condition.name,
                desc: tests[iter].condition.desc,
                instruction: tests[iter].condition.message,
                solution: tests[iter].solution,
                conditionId: tests[iter].condition._id,
                isVisible: tests[iter].isVisible,
            });
        }
        setIsUrlChecked(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tests]);
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        dispatch(getTestsBiPupilId(query.get("employeeId")));
    }, [dispatch, location]);

    useEffect(() => {
        dispatch(getConditions());
    }, [dispatch]);

    const formatDate = useCallback((date) => {
        return new Date(date).toLocaleString("ru", {
            hour: "numeric",
            minute: "numeric",
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    }, []);

    const infoBtnHandler = useCallback(
        (e) => {
            if (!info) {
                e.stopPropagation();
                const iter = e.currentTarget.id;
                return setInfo({
                    id: tests[iter]._id,
                    name: tests[iter].condition.name,
                    desc: tests[iter].condition.desc,
                    instruction: tests[iter].condition.message,
                    solution: tests[iter].solution,
                    conditionId: tests[iter].condition._id,
                    author: tests[iter].condition.methodAuthor,
                    message: tests[iter].condition.message,
                    desTeacher: tests[iter].condition.desTeacher,
                    scales: tests[iter].condition.scales,
                    fromTests: true,
                    isVisible: tests[iter].isVisible,
                });
            }

            setInfo(null);
        },
        [info, tests]
    );

    const isCreateHandler = () => setIsCreate(!isCreate);

    const createTestHandler = async (e) => {
        try {
            const query = new URLSearchParams(location.search);
            const pupilId = query.get("employeeId");
            const classId = query.get("divisionId");
            const schoolId = query.get("departmentId");
            const { data } = await axios.post("tests/create", {
                pupils: [pupilId],
                classId,
                schoolId,
                conditionId: e.currentTarget.id,
                isVisible: false,
            });

            dispatch(showAlert({ type: "success", text: data.message }));

            setTimeout(() => {
                dispatch(hideAlert());
            }, 2000);
            dispatch(getTestsBiPupilId(pupilId));
            setIsCreate(!isCreate);
        } catch (e) {
            if (e.response.data.message) {
                dispatch(showAlert({ type: "error", text: e.response.data.message }));
            } else {
                dispatch(showAlert({ type: "error", text: e.message }));
            }
        }
    };

    const createTestInfoHandler = (e) => {
        e.stopPropagation();
        const i = e.currentTarget.id;
        setInfo({
            name: conditions[i].name,
            desc: conditions[i].message,
            conditionId: conditions[i]._id,
            author: conditions[i].methodAuthor,
            desTeacher: conditions[i].desTeacher,
            message: conditions[i].message,
            scales: conditions[i].scales,
            isVisible: false,
        });
    };

    if (loading || !tests || !isUrlChecked) {
        return <Loader />;
    }
    if (info) {
        return (
            <Layout>
                <Info {...info} handler={infoBtnHandler} />
            </Layout>
        );
    }

    if (isCreate) {
        return (
            <Layout>
                <div className="page create-test">
                    <header className="page__header">
                        <button
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                            onClick={isCreateHandler}
                        />
                        <p className="page__title">Отправить тест</p>
                    </header>

                    <div className="page__content">
                        <h3>Выберите методику</h3>
                        <ul className="list">
                            {conditions.map((condition, index) => (
                                <li
                                    id={condition._id}
                                    key={condition._id}
                                    className="list__item"
                                    onClick={createTestHandler}
                                >
                                    <button
                                        id={index}
                                        className="list__info-btn"
                                        onClick={createTestInfoHandler}
                                    />
                                    <p>{condition.name}</p>
                                    <h5 className="list__item-count list__item-count-margin">
                                        {condition.methodAuthor}
                                    </h5>
                                    <h5 className="list__item-count">
                                        {condition.body.questions.length} вопросов в методике
                                    </h5>
                                    <h5 className="list__item-count">
                                        {condition.scales.length}{" "}
                                        {declinations(condition.scales.length, "scale")}
                                    </h5>
                                    {condition.message ? (
                                        <p
                                            className="list__desc"
                                            dangerouslySetInnerHTML={{
                                                __html: condition.message.slice(0, 64) + " ...",
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page page__small tests-page">
                <header className="page__header">
                    <button
                        onClick={goBack}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    {role === "psych" && (
                        <button
                            onClick={isCreateHandler}
                            className="icon-btn page__icon-btn page__icon-btn_right icon-btn_add"
                        />
                    )}

                    <p className="page__title tests-page__title">Тесты</p>
                </header>

                <div className="tests-page__main">
                    <div>
                        <p className="tests-page__main-title">Не пройденные</p>
                        <div className="list">
                            {tests.map((test, index) => {
                                if (!test.solutionToken) {
                                    if (!test.condition) return null;
                                    return (
                                        <div
                                            id={index}
                                            key={index}
                                            className="list__item"
                                            onClick={(e) => {
                                                infoBtnHandler(e);
                                            }}
                                        >
                                            <p>
                                                {role === "psych" || test.isVisible
                                                    ? test.condition.name
                                                    : "Тест " + (index + 1)}
                                            </p>
                                            {role === "psych" ||
                                                (test.isVisible && test.condition.desc && (
                                                    <p
                                                        className="list__desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                (test.condition.desc || "").slice(
                                                                    0,
                                                                    64
                                                                ) + " ...",
                                                        }}
                                                    />
                                                ))}
                                            {role === "psych" && (
                                                <>
                                                    <h5 className="list__item-count list__item-count-margin">
                                                        {test.condition.methodAuthor}
                                                    </h5>
                                                    <h5 className="list__item-count">
                                                        {test.condition.body.questions.length}{" "}
                                                        вопросов в методике
                                                    </h5>
                                                    <h5 className="list__item-count">
                                                        {test.condition.scales.length}{" "}
                                                        {declinations(
                                                            test.condition.scales.length,
                                                            "scale"
                                                        )}
                                                    </h5>
                                                </>
                                            )}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>

                <div className="tests-page__passed">
                    <p className="tests-page__passed-title">Пройденные тесты</p>
                    <div className="list">
                        {tests.map((test, index) => {
                            if (test.solutionToken) {
                                if (!test.condition) return null;
                                return (
                                    <div
                                        key={index}
                                        className="tests-page__passed-test"
                                        style={{ position: "relative" }}
                                    >
                                        <p className="list__desc tests-page__date">
                                            {formatDate(test.date)}
                                        </p>
                                        {test.isVisible && (
                                            <span
                                                className="list__desc"
                                                style={{ position: "absolute", top: 0, right: 0 }}
                                            >
                                                Результат доступен
                                            </span>
                                        )}
                                        <div
                                            className="list__item"
                                            onClick={() => {
                                                (role === "psych" || test.isVisible) &&
                                                    history.push(`/solutions/${test._id}`);
                                            }}
                                        >
                                            {role === "psych" ||
                                                (test.isVisible && (
                                                    <button
                                                        id={index}
                                                        className="list__info-btn"
                                                        onClick={infoBtnHandler}
                                                    />
                                                ))}
                                            <p>
                                                {role === "psych" || test.isVisible
                                                    ? test.condition.name
                                                    : "Тест " + (index + 1)}
                                            </p>
                                            {role === "psych" && (
                                                <>
                                                    <h5 className="list__item-count list__item-count-margin">
                                                        {test.condition.methodAuthor}
                                                    </h5>
                                                    <h5 className="list__item-count">
                                                        {test.condition.body.questions.length}{" "}
                                                        вопросов в методике
                                                    </h5>
                                                    <h5 className="list__item-count">
                                                        {test.condition.scales.length}{" "}
                                                        {declinations(
                                                            test.condition.scales.length,
                                                            "scale"
                                                        )}
                                                    </h5>
                                                </>
                                            )}
                                            {role === "psych" ||
                                                (test.isVisible && (
                                                    <p
                                                        className="list__desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                (test.condition.desc || "").slice(
                                                                    0,
                                                                    64
                                                                ) + " ...",
                                                        }}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
