import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showAlert } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Input } from "components/forms/Input";

/**
 * @api {get} /schools/:schoolId/classes/create создание класса
 * @apiGroup schools and classes
 * @apiName создание класса
 *
 *  @apiParam {String} schoolId ID школы
 *
 * @apiDescription <p>Страница создания класса</p>
 * <p>Компонент <code>ClassForm.js</code> <a href="../client/src/pages/classes/ClassForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /classes/:id редактирование класса
 * @apiGroup schools and classes
 * @apiName создание редактирование
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница редактирования класса</p>
 * <p>Компонент <code>ClassForm.js</code> <a href="../client/src/pages/classes/ClassForm.js">ссылка на файл</a></p>
 */

const initialState = {
    name: "",
    max: "",
};

const formFields = {
    name: "Название",
    max: "Максимальное количество",
};

export const ClassForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classId = useParams().id;
    const { departmentId } = useParams();
    const [data, setData] = useState(null);
    const [form, setForm] = useState(initialState);
    const [incorrectFields, setIncorrectFields] = useState([]);

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}`);
                setData(data);
                setForm({
                    ...initialState,
                    name: data.name,
                    max: data.max,
                });
            })();
        }
    }, [classId]);

    const changeHandler = (event) => {
        let { name, value } = event.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        setForm({ ...form, [name]: value });
    };

    const submit = async (e) => {
        e.preventDefault();
        const errorFields = [];
        if (!form.name.trim()) errorFields.push("name");
        if (form.max < 1) errorFields.push("max");

        if (!errorFields.length) {
            try {
                if (data) {
                    await axios.post(`classes/${data._id}`, {
                        ...form,
                        departmentId,
                    });
                } else {
                    form.schoolId = departmentId;
                    await axios.post("classes/create", form);
                }

                history.goBack();
            } catch (err) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: "Ошибка создания отдела",
                    })
                );
            }
        } else {
            const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
            setIncorrectFields(errorFields);
            dispatch(
                showAlert({
                    type: "error",
                    text: `Некорректно заполнены поля:\n\n${incorrectFieldsMessage}`,
                })
            );
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Отделы</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>
                        {data
                            ? "Изменить отдел"
                            : "Добавить отдел"}
                    </h3>
                    {/*<Input*/}
                    {/*    id="number"*/}
                    {/*    type="number"*/}
                    {/*    name="number"*/}
                    {/*    placeholder="Номер"*/}
                    {/*    onChange={changeHandler}*/}
                    {/*    value={form.number}*/}
                    {/*    isError={incorrectFields.includes("number")}*/}
                    {/*/>*/}
                    <Input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Название"
                        onChange={changeHandler}
                        value={form.name}
                        isError={incorrectFields.includes("name")}
                    />
                    <Input
                        id="max"
                        type="number"
                        name="max"
                        placeholder="Максимальное количество"
                        onChange={changeHandler}
                        value={form.max}
                        min="1"
                        isError={incorrectFields.includes("max")}
                    />
                    <p style={{ opacity: 0.3 }}>
                        Поле “Максимальное количество” запрашивает у вас число пользователей для
                        того, чтобы в системе не смог зарегистрироваться дополнительно кто-то
                        внешний (например если кто-то разместит активный QR код созданного вами
                        отдела в Интернете)
                    </p>
                </div>
                <button onClick={submit} disabled={false} className="main-btn">
                    {data ? "Изменить отдел" : "Добавить отдел"}
                </button>
            </div>
        </Layout>
    );
};
