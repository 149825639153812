export const yearsOldCounter = (birthday) => {
    const nowDate = Date.now();
    const birthdayDate = new Date(birthday);
    const days = (nowDate - birthdayDate) / (60 * 60 * 24 * 1000);
    return `${Math.floor(days / 365)}`;
};

export const returnRightWordForYears = (val) => {
    if (val <= 12) {
        if (val === 1) {
            return "год";
        } else if (val >= 2 && val <= 4) {
            return "года";
        } else {
            return "лет";
        }
    } else {
        if (val % 10 === 1) {
            return "год";
        } else if (val % 10 >= 2 && val % 10 <= 4) {
            return "года";
        } else {
            return "лет";
        }
    }
};
