import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { getConditionsINedded, changeConditionId } from "redux/actions/setConditionActions";
import { getSchoolsINedded, getSchoolINedded } from "redux/actions/setSchoolActions";
import { getClassINedded } from "redux/actions/setClassActions";
import { stepUp, stepDown, setStep } from "redux/actions/setTestActions";
import { showAlert, hideAlert } from "redux/actions/setAppActions";
import fetch from "redux/fetch";
import { STEP_RESET } from "redux/types";
import { declinations } from "helpers/declinations";
import { ProgressBar } from "components/ProgressBar";
import { Loader } from "components/Loader";
import { Info } from "components/Info";
import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";

/**
 * @api {get} /tests/create отправка теста ученику
 * @apiGroup tests
 * @apiName отправка теста ученику
 *
 * @apiDescription <p>Страница отправки теста ученику</p>
 * <p>Компонент <code>CreateTestPage.js</code> <a href="../client/src/pages/CreateTestPage.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /tests/create/:id отправка теста по id
 * @apiGroup tests
 * @apiName отправка теста по id
 *
 * @apiParam {String} id ID теста
 *
 * @apiDescription <p>Страница отправки теста ученику по id теста</p>
 * <p>Компонент <code>CreateTestPage.js</code> <a href="../client/src/pages/CreateTestPage.js">ссылка на файл</a></p>
 */

export const CreateTestPage = () => {
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { step, conditions, conditionId } = useSelector((state) => state.test);
    const { schools, schoolData, classData } = useSelector((state) => state.school);
    const { loading } = useSelector((state) => state.app);
    const [selectAll, setSelectAll] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const [pupils, setPupils] = useState([]);
    const [filteredPupils, setFilteredPupils] = useState([]);
    const [info, setInfo] = useState(null);
    const { filters } = useSelector((state) => state.createTest);
    let classId;
    if (classData) {
        classId = classData._id;
    }
    useEffect(() => {
        dispatch(getConditionsINedded());
        dispatch(getSchoolsINedded());
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            if (classId) {
                let pupilsData = await fetch(`/api/classes/${classId}/pupils`, { method: "GET" });
                let pupils = [];

                if (pupilsData?.length) {
                    pupils = pupilsData.filter(({ isArchive }) => !isArchive);
                }

                if (pupils) {
                    if (filters.sex) {
                        let filteredPupils = [];
                        for (let i = 0; i < pupils.length; i++) {
                            if (pupils[i].sex === filters.sex) {
                                filteredPupils.push(pupils[i]);
                            }
                        }
                        pupils = filteredPupils;
                    }

                    if (filters.years) {
                        let targetAges = filters.years.replace(/\s/g, "").split(",");
                        let filteredPupils = [];
                        for (let i = 0; i < pupils.length; i++) {
                            let currentYears = pupils[i].birthday;
                            let date = new Date(currentYears);
                            let ageDateNumber = Date.now() - date;
                            let ageDate = new Date(ageDateNumber);
                            let year = ageDate.getUTCFullYear();
                            let age = Math.abs(1970 - year);
                            for (let j = 0; j < targetAges.length; j++) {
                                if (targetAges[j].toString() === age.toString()) {
                                    filteredPupils.push(pupils[i]);
                                }
                            }
                        }
                        pupils = filteredPupils;
                    }

                    if (filters.category) {
                        let filteredPupils = [];
                        for (let i = 0; i < pupils.length; i++) {
                            for (let j = 0; j < pupils[i].categories.length; j++) {
                                if (pupils[i].categories[j] === filters.category) {
                                    filteredPupils.push(pupils[i]);
                                }
                            }
                        }
                        pupils = filteredPupils;
                    }
                }

                setFilteredPupils(pupils);
            }
        })();
    }, [classId, filters]);

    useEffect(() => {
        if (id) {
            dispatch(changeConditionId(id));
            dispatch(setStep(2));
        }
    }, [dispatch, id]);

    const backBtnHandler = () => {
        if (id && step === 2) {
            dispatch(stepDown());
            history.goBack();
        } else if (step < 2) {
            history.goBack();
        } else {
            dispatch(stepDown());
        }
    };

    const conditionsHandler = (evt) => {
        dispatch(changeConditionId(evt.currentTarget.id));
        dispatch(stepUp());
    };

    const schoolsHandler = (evt) => {
        dispatch(getSchoolINedded(evt.currentTarget.id));
        dispatch(stepUp());
    };

    const classesHandler = (evt) => {
        dispatch(getClassINedded(evt.currentTarget.id));
        dispatch(stepUp());
    };

    const visibleHandler = () => {
        setIsVisible(!isVisible);
    };

    const checkboxHandler = (evt) => {
        const array = [...pupils];
        const index = array.indexOf(evt.target.name);

        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(evt.target.name);
        }
        setSelectAll(false);
        setPupils(array);
    };

    const selectAllHandler = () => {
        if (selectAll) {
            setPupils([]);
            setSelectAll(false);
        } else {
            const array = [];
            for (let pupil of classData.pupils) {
                array.push(pupil._id);
            }
            setSelectAll(true);
            setPupils(array);
        }
    };

    const postTests = useCallback(async () => {
        try {
            const { data } = await axios.post("tests/create", {
                pupils,
                conditionId,
                classId: classData._id,
                schoolId: schoolData._id,
                isVisible,
            });

            dispatch(showAlert({ type: "success", text: data.message }));

            setTimeout(() => {
                dispatch(hideAlert());
                if (id) {
                    history.goBack();
                } else {
                    dispatch({ type: STEP_RESET });
                }
            }, 2000);
        } catch (e) {
            if (e.response.data.message) {
                dispatch(showAlert({ type: "error", text: e.response.data.message }));
            } else {
                dispatch(showAlert({ type: "error", text: e.message }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pupils, conditionId, classData, schoolData, isVisible, dispatch]);
    const infoBtnHandler = useCallback(
        (e) => {
            if (!info) {
                e.stopPropagation();
                const iter = e.target.id;

                setInfo({
                    name: conditions[iter].name,
                    desc: conditions[iter].message,
                    conditionId: conditions[iter]._id,
                    author: conditions[iter].methodAuthor,
                    desTeacher: conditions[iter].desTeacher,
                    message: conditions[iter].message,
                    scales: conditions[iter].scales,
                });
            } else {
                setInfo(null);
            }
        },
        [info, conditions]
    );

    const sendHandler = () => {
        postTests();
    };

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={infoBtnHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page create-test">
                <header className="page__header">
                    <button
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        onClick={backBtnHandler}
                    />
                    <p className="page__title">Отправить тест</p>
                    {step === 4 ? (
                        <Link
                            to={`/tests/create/${classId}/filters`}
                            className={
                                !!Object.values(filters).filter((val) => !!val.length).length
                                    ? `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_active`
                                    : `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`
                            }
                        >
                            {!!Object.values(filters).filter((val) => !!val.length).length && (
                                <span>
                                    {Object.values(filters).filter((val) => !!val.length).length}
                                    <div className="dot"></div>
                                </span>
                            )}
                        </Link>
                    ) : (
                        ""
                    )}
                </header>

                <ProgressBar step={step} total={5} color="red" />

                <TestsStep
                    step={step}
                    loading={loading}
                    conditions={conditions}
                    clickHandler={conditionsHandler}
                    infoBtnHandler={infoBtnHandler}
                />

                <SchoolsStep
                    step={step}
                    loading={loading}
                    schools={schools}
                    clickHandler={schoolsHandler}
                />

                <ClassesStep
                    loading={loading}
                    step={step}
                    schoolData={schoolData}
                    clickHandler={classesHandler}
                />

                <PupilsStep
                    step={step}
                    classData={classData}
                    pupils={pupils}
                    loading={loading}
                    selectAllHandler={selectAllHandler}
                    checkboxHandler={checkboxHandler}
                    clickHandler={sendHandler}
                    visibleHandler={visibleHandler}
                    selectAll={selectAll}
                    isVisible={isVisible}
                    filteredPupils={filteredPupils}
                />
            </div>
        </Layout>
    );
};

const TestsStep = (props) => {
    if (props.step !== 1) {
        return null;
    }

    if (props.loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page__content">
                <h3>Выберите методику</h3>
                <ul className="list">
                    {props.conditions.map((condition, index) => {
                        return (
                            <li
                                onClick={props.clickHandler}
                                className="tests-list__item"
                                key={condition._id}
                                id={condition._id}
                            >
                                <div>
                                    <p>{condition.name}</p>
                                    <h5 className="list__item-count list__item-count-margin">
                                        {condition.body.questions.length} вопросов в методике
                                    </h5>
                                    <h5 className="list__item-count">
                                        {condition.scales.length}{" "}
                                        {declinations(condition.scales.length, "scale")}
                                    </h5>
                                    {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                    <h5></h5>
                                    {condition.desc ? (
                                        <p
                                            className="list__desc"
                                            dangerouslySetInnerHTML={{
                                                __html: condition.desc.slice(0, 64) + " ...",
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <button
                                    id={index}
                                    className="tests-list__info-btn"
                                    onClick={props.infoBtnHandler}
                                ></button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Layout>
    );
};

const SchoolsStep = (props) => {
    if (props.step !== 2) {
        return null;
    }

    if (props.loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page__content">
                <h3>Выберите департамент</h3>
                <ul className="list">
                    {props.schools.map((school) => {
                        return (
                            <li
                                onClick={props.clickHandler}
                                className="list__item"
                                key={school._id}
                                id={school._id}
                            >
                                <p>{school.name}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Layout>
    );
};

const ClassesStep = (props) => {
    if (props.step !== 3) {
        return null;
    }

    if (props.loading || !props.schoolData) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page__content">
                <h3>Выберите отдел</h3>
                <ul className="list">
                    {props.schoolData.classes.map((group) => {
                        return (
                            <li
                                onClick={props.clickHandler}
                                className="list__item"
                                key={group._id}
                                id={group._id}
                            >
                                <p>{group.name}</p>
                                <p className="list__desc">{group.pupils.length} участников</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Layout>
    );
};

const PupilsStep = (props) => {
    const [isSureAlert, setIsSureAlert] = useState(false);

    function onSendClick() {
        setIsSureAlert(true);
    }

    function onCloseClick() {
        setIsSureAlert(false);
    }

    if (props.step !== 4) {
        return null;
    }

    if (props.loading || !props.classData) {
        return <Loader />;
    }

    const group = props.classData;
    const filteredPupils = props.filteredPupils;

    return (
        <Layout>
            <div className="page__content" style={{ paddingBottom: 100 + "px" }}>
                <h3>Выберите сотрудников</h3>
                <p className="page__desc">
                    {group.name}, всего {group.pupils.length} сотрудников
                </p>
                <p
                    className={`select-all ${props.selectAll ? "select-all_active" : null}`}
                    onClick={props.selectAllHandler}
                >
                    Выбрать всех
                </p>
                <div className="list">
                    {filteredPupils.map((pupil) => {
                        return (
                            <div key={pupil._id} className="list__checkbox">
                                <input
                                    id={pupil._id}
                                    type="checkbox"
                                    name={pupil._id}
                                    checked={props.pupils.includes(pupil._id)}
                                    onChange={props.checkboxHandler}
                                    value={pupil._id}
                                />
                                <label htmlFor={pupil._id}>
                                    <div>
                                        <p>
                                            {pupil.surname} {pupil.name}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                </div>
                <div className="bool-field">
                    <p>Показывать результат сотрудникам</p>
                    <div
                        onClick={props.visibleHandler}
                        className={`swipe ${props.isVisible && "swipe_active"}`}
                    />
                </div>
                <div className="send-btn">
                    <button onClick={onSendClick} className="main-btn">
                        Отправить
                    </button>
                </div>
            </div>
            {isSureAlert ? (
                <SureAlert
                    type="createTest"
                    handleClose={onCloseClick}
                    handleSubmit={() => {
                        props.clickHandler();
                        onCloseClick();
                    }}
                />
            ) : (
                ""
            )}
        </Layout>
    );
};
