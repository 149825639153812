import React from "react";
import { Link } from "react-router-dom";

import { problemsList } from "./problems/problemsList";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import "../static/scss/problemsPage.scss";
import chat from "../static/img/ic-whatsapp.svg";
import arrowRight from "../static/img/ic-arrow-next.svg";

/**
 * @api {get} /workWithMethods/problems список современных проблем подросткового возраста
 * @apiGroup methods
 * @apiName список современных проблем подросткового возраста
 *
 * @apiDescription <p>Страница со списком современных проблем подросткового возраста</p>
 * <p>Компонент <code>ProblemsPage.js</code> <a href="../client/src/pages/ProblemsPage.js">ссылка на файл</a></p>
 */

export default function ProblemsPage() {
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Работа с методиками</p>
                </header>
                <div className="workWithMethods-page__wrapper">
                    <div className="list">
                        {problemsList.map((item, id1) => {
                            return (
                                <Link
                                    to={"/workWithMethods/problems/" + id1}
                                    className="problems-list__item"
                                    key={item.title + id1}
                                >
                                    <div className="problems-list__row">
                                        <img className="problems-list__img" src={chat} alt="" />
                                        <p className="problems-list__text">{item.title}</p>
                                    </div>
                                    <div className="problems-list__btn">
                                        <img
                                            style={{ filter: "invert(1)" }}
                                            src={arrowRight}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
}
