import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/ru";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getMeetings, readMeeting } from "redux/actions/setMeetingActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

/**
 * @api {get} /pupils/:pupilId/meetings встречи ученика
 * @apiGroup pupils
 * @apiName встречи ученика
 *
 * @apiParam {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница встреч ученика</p>
 * <p>Компонент <code>Meetings.js</code> <a href="../client/src/pages/meetings/Meetings.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings список встреч
 * @apiGroup meetings
 * @apiName список встреч
 * *
 * @apiDescription <p>Страница со списоком встреч</p>
 * <p>Компонент <code>Meetings.js</code> <a href="../client/src/pages/meetings/Meetings.js">ссылка на файл</a></p>
 */

export const Meetings = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pupilId } = useParams();
    const { meetings, meeting } = useSelector((state) => state.meeting);
    const { role } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getMeetings(pupilId));
    }, [dispatch, pupilId, role, meeting]);

    const clickHandler = (id) => {
        const href = pupilId ? `/employees/${pupilId}/meetings/${id}` : `/meetings/${id}`;

        history.push(href);
    };

    const confirmBtnHandler = (e, id) => {
        e.stopPropagation();

        dispatch(readMeeting(id));
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    {role === "psych" && (
                        <Link
                            to={
                                pupilId
                                    ? `/employees/${pupilId}/meetings/create`
                                    : "/meetings/departments"
                            }
                            className="icon-btn  page__icon-btn page__icon-btn_right icon-btn_add"
                        />
                    )}
                    <p className="page__title">Встречи</p>
                </header>

                <div className="school-page__wrapper">
                    <div className="list">
                        {meetings.map((meeting) => (
                            <div key={meeting._id} className="list__item-wrap">
                                <p className="list__item-date">
                                    {moment(meeting.date).utc().format("HH:mm, DD MMM, YYYY")}
                                </p>
                                <div
                                    onClick={() => {
                                        clickHandler(meeting._id);
                                    }}
                                    className="list__item list__item-meeting"
                                >
                                    <p className="list__item-meeting-title">
                                        <span className="list__item-meeting-title-name">
                                            {meeting.name}
                                        </span>
                                        {meeting.readAt ? (
                                            <span className="list__item-meeting-status list__item-meeting-read">
                                                подтверждено
                                            </span>
                                        ) : (
                                            <span className="list__item-meeting-status list__item-meeting-not-read">
                                                не подтверждено
                                            </span>
                                        )}
                                    </p>
                                    <p className="list__desc list__item-meeting-desc">
                                        {meeting.description}
                                    </p>

                                    <div className="list__item-meeting-bottom-block">
                                        <p>
                                            {role === "psych" && (
                                                <>
                                                    {meeting.pupil?.surname} {meeting.pupil?.name}
                                                </>
                                            )}
                                        </p>
                                        {role === "pupil" && !meeting.readAt && (
                                            <button
                                                className="list__item-meeting-btn"
                                                onClick={(e) => {
                                                    confirmBtnHandler(e, meeting._id);
                                                }}
                                            >
                                                Подтверждаю
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {role === "psych" && (
                    <Link
                        to={
                            pupilId
                                ? `/employees/${pupilId}/meetings/create`
                                : "/meetings/departments"
                        }
                        className="main-btn main-btn_purple"
                    >
                        Назначить встречу
                    </Link>
                )}
            </div>
        </Layout>
    );
};
