import React, { memo, useState } from "react";
import classNames from "classnames";

import whyPhone from "static/img/landing/why-phone.png";

export const LandingWhy = memo(({ showPopupHandler }) => {
    const [tab, setTab] = useState(1);

    return (
        <section className="landing__section" id="landing-why">
            <div className="landing__why">
                <div className="landing__why-block--left">
                    <div>
                        <h2 className="landing__section-title landing__why-title">
                            Зачем наше решение
                        </h2>
                        <div className="landing__why-buttons-tab">
                            <button
                                onClick={() => {
                                    setTab(1);
                                }}
                                className={classNames({ active: tab === 1 })}
                            >
                                Руководству
                            </button>
                            <button
                                onClick={() => {
                                    setTab(2);
                                }}
                                className={classNames({ active: tab === 2 })}
                            >
                                Психологу и HR
                            </button>
                            <button
                                onClick={() => {
                                    setTab(3);
                                }}
                                className={classNames({ active: tab === 3 })}
                            >
                                Сотруднику
                            </button>
                        </div>

                        {tab === 1 && (
                            <div className="landing__why-content">
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">01</span>
                                    <p>
                                        Сокращение временных и ресурсных трат при потере сотрудника
                                        с выгоранием
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">02</span>
                                    <p>
                                        Выявление в реальном времени поведенческих особенностей и
                                        конфликтов внутри коллектива
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">03</span>
                                    <p>
                                        Прозрачная обратная связь от сотрудников (в том числе
                                        анонимная)
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">04</span>
                                    <p>
                                        Полное покрытие сотрудников по HR взаимодействию (в том
                                        числе во время удаленной работы)
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">05</span>
                                    <p>
                                        Улучшение взаимоотношений в коллективе и создание здоровой
                                        атмосферы
                                    </p>
                                </div>
                            </div>
                        )}

                        {tab === 2 && (
                            <div className="landing__why-content">
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">01</span>
                                    <p>
                                        Комплекс методик по системному выявлению проблем
                                        стресс-менеджмента
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">02</span>
                                    <p>
                                        Цифровое пространство для коммуникации с сотрудниками и
                                        получению обратной связи
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">03</span>
                                    <p>
                                        Конструктор для создания любого типа дополнительного
                                        анкетирования и компьютеризации методик
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">04</span>
                                    <p>
                                        Доступность использования сервиса при наличии только
                                        мобильного телефона
                                    </p>
                                </div>
                            </div>
                        )}

                        {tab === 3 && (
                            <div className="landing__why-content">
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">01</span>
                                    <p>
                                        Донесение любой информации до руководства компании анонимно
                                        и безопасно (каждый может обозначить возникающие трудности и
                                        проблемы)
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">02</span>
                                    <p>
                                        Кнопка «Хочу поговорить» для коммуникации с штатным
                                        психологом или сотрудником HR конфиденциально
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">03</span>
                                    <p>Получение и хранение рекомендаций психолога на платформе</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <button
                        onClick={showPopupHandler}
                        className="landing__button landing__button-secondary landing__why-button-want"
                    >
                        Хочу внедрить
                    </button>
                </div>

                <div className="landing__section-block-dark landing__why-block--right">
                    <img src={whyPhone} alt="" />
                </div>
            </div>
        </section>
    );
});
