import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showAlert } from "redux/actions/setAppActions";

export const useCreateReport = () => {
    const dispatch = useDispatch();
    const [isReportLoading, setIsReportLoading] = useState(false);

    const createReport = (url, fileName) => {
        if (!isReportLoading) {
            setIsReportLoading(true);

            axios
                .get(url, { responseType: "blob" })
                .then((response) => {
                    const link = document.createElement("a");

                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    dispatch(
                        showAlert({
                            type: "error",
                            text: "Ошибка загрузки отчета. Попробуйте обновить страницу",
                        })
                    );
                })
                .finally(() => {
                    setIsReportLoading(false);
                });
        }
    };

    return {
        isReportLoading,
        createReport,
    };
};
