import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { CREATE_ALARM, GET_ALARMS, GET_LAST_ALARM } from '../types'


export function getAlarms() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/alarms`,
                { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_ALARMS, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function getLastAlarm() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/alarms/last`,
                { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_LAST_ALARM, payload: json.alarm })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function createAlarm(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())

            const json = await fetch(`/api/alarms/create`,
                {
                    method: 'POST',
                    body: JSON.stringify({ ...data }),
                    headers: { 'Content-Type': 'application/json', }
                }, getState, dispatch)

            dispatch({ type: CREATE_ALARM, payload: json.alarm })
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'success', text: 'Запрос успешно отправлен' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function readAlarms() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            await fetch(`/api/alarms/read`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', }
                }, getState, dispatch)
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}