import React from 'react';
import { IMaskInput } from 'react-imask';

export const Input = ({
    name,
    value,
    onChange,
    placeholder,
    isError = false,
    type = 'text',
    mask = null,
    maskOptions = {}
}) => {
    return (
        <div className={`input-field ${isError ? 'error' : ''}`}>
            {mask ? (
                <IMaskInput
                    id={name}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    mask={mask}
                    {...maskOptions}
                />
            ) : (
                <input
                    id={name}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                />
            )}
            <label htmlFor={name}>{placeholder}</label>
        </div>
    );
};
