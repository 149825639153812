import { NavLink } from 'react-router-dom'
import { RiHomeLine, RiSettings4Line } from 'react-icons/ri'


export const Navbar = () => {
    return (
        <div style={{
            left: '0',
            right: '0',
            bottom: '0',
            height: '60px',
            position: 'fixed',
            background: '#fff',
        }}>
            <nav style={{
                width: '100%',
                height: '100%',
                maxWidth: '640px',
                padding: '0 4.5rem',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <NavLink to={`/dashboard`} >
                    <RiHomeLine style={{
                        fontSize: '24px',
                        color: 'rgba(18, 36, 67, 0.6)'
                    }} />
                </NavLink>
                <NavLink to={`/settings`} >
                    <RiSettings4Line style={{
                        fontSize: '24px',
                        color: 'rgba(18, 36, 67, 0.6)'
                    }} />
                </NavLink>
            </nav>
        </div>
    )
}