import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { FaCheck } from "react-icons/fa";

import { hideNotice, showAlert, showNotice } from "redux/actions/setAppActions";
import { addVkScriptToPage } from "helpers/auth";
import { Alert } from "components/Alert";
import { Notice } from "components/Notice";
import { Input } from "components/forms/Input";
import { VK_APP_ID } from "config";
import policy from "../static/docs/policy.pdf";
import terms from "../static/docs/LSSR_terms_of_use.pdf";

const initialState = {
    sex: "1",
    name: "",
    patronymic: "",
    surname: "",
    birthday: "",
    login: "",
    password: "",
};

const formFields = {
    surname: "Фамилия",
    name: "Имя",
    birthday: "Дата рождения",
    login: "Логин",
    password: "Пароль",
};

/**
 * @api {get} /pupil/:classId регистрация ученика
 * @apiGroup pupils
 * @apiName регистрация ученика
 *
 * @apiParam {String} classId ID класса
 *
 * @apiDescription <p>Страница регистрации ученика</p>
 * <p>Компонент <code>RegisterPupil.js</code> <a href="../client/src/pages/RegisterPupil.js">ссылка на файл</a></p>
 */

export const RegisterPupil = () => {
    const dispatch = useDispatch();
    const classId = useParams().classId;
    const [group, setGroup] = useState(null);
    const [form, setForm] = useState(initialState);
    const { alert, notice } = useSelector((state) => state.app);
    const [isBirthdayCorrect, setIsBirthdayCorrect] = React.useState(false);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [thirdCheckboxChecked, setThirdCheckboxChecked] = useState(false);
    const [fourthCheckboxChecked, setFourthCheckboxChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [incorrectFields, setIncorrectFields] = useState([]);

    function firstCheckboxHandler() {
        setFirstCheckboxChecked(!firstCheckboxChecked);
    }

    function secondCheckboxHandler() {
        setSecondCheckboxChecked(!secondCheckboxChecked);
    }

    function thirdCheckboxHandler() {
        setThirdCheckboxChecked(!thirdCheckboxChecked);
    }

    function fourthCheckboxHandler() {
        setFourthCheckboxChecked(!fourthCheckboxChecked);
    }

    useEffect(() => {
        if (classId) {
            (async () => {
                try {
                    const { data } = await axios.get(`classes/${classId}`);
                    setGroup(data);

                    addVkScriptToPage();
                } catch (err) {
                    dispatch(
                        showAlert({
                            type: "error",
                            text: "Не удалось получить информацию об отделе. Проверьте корректность ссылки на регистрацию",
                        })
                    );

                    setTimeout(() => {
                        window.location.href = "/";
                    }, 3000);
                }
            })();
        }
    }, [dispatch, classId]);

    const VkAuthHandler = () => {
        //eslint-disable-next-line no-undef
        VK.init({
            apiId: VK_APP_ID,
        });

        //eslint-disable-next-line no-undef
        VK.Auth.login((res) => {
            if (res?.session) {
                const user = res.session?.user;

                if (user?.id) {
                    setForm({
                        ...form,
                        vkId: user.id,
                    });
                }
            }
        });
    };

    const register = async () => {
        if (
            firstCheckboxChecked &&
            secondCheckboxChecked &&
            thirdCheckboxChecked &&
            fourthCheckboxChecked
        ) {
            const errorFields = [];
            const forbiddenSymbols = /[%;="*?#&()_~]/gi;
            if (
                form.surname.length < 2 ||
                form.surname.length > 20 ||
                forbiddenSymbols.test(form.surname)
            )
                errorFields.push("surname");
            if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name))
                errorFields.push("name");
            if (!isBirthdayCorrect) errorFields.push("birthday");
            if (!form.login) errorFields.push("login");
            if (form.password.length < 10) errorFields.push("password");

            if (!errorFields.length) {
                try {
                    const data = { ...form };
                    const ISODate = moment(data.birthday, "DD.MM.YYYY", true).toISOString();
                    const isVk = form.vkId ? "или авторизуйтесь через ВК" : "";

                    if (ISODate) {
                        data.birthday = ISODate;
                    }

                    await axios.post("pupil/register", {
                        ...data,
                        classId,
                        psychId: group?.psych?._id,
                    });
                    setForm(initialState);
                    dispatch(
                        showNotice(`Спасибо за регистрацию на TestU.online!
                                            Чтобы войти в систему введите свой логин и пароль ${isVk}`)
                    );
                    setIsSuccess(true);
                } catch (e) {
                    if (e.response?.data?.message) {
                        dispatch(showNotice(e.response.data.message));
                    } else {
                        dispatch(showNotice(e.message));
                    }
                }
            } else {
                const incorrectFieldsMessage = errorFields
                    .map((item) => formFields[item])
                    .join("\n");
                setIncorrectFields(errorFields);
                dispatch(
                    showAlert({
                        type: "error",
                        text: `Некорректно заполнены поля:\n\n${incorrectFieldsMessage}`,
                    })
                );
            }
        }
    };

    useEffect(() => {
        const isDate = moment(form.birthday, "DD.MM.YYYY", true).isBetween(
            -1577934000000,
            moment.now()
        );

        if (isDate) {
            if (!isBirthdayCorrect) {
                setIsBirthdayCorrect(true);
            }
        } else {
            if (isBirthdayCorrect) {
                setIsBirthdayCorrect(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.birthday]);

    const changeHandler = (e) => {
        const { name, value } = e.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        const newValue = ["name", "surname", "patronymic", "login", "password"].includes(name)
            ? value.trim()
            : value;

        setForm({
            ...form,
            [name]: newValue,
        });
    };

    const hideNoticeHandler = () => {
        dispatch(hideNotice());

        if (isSuccess) {
            window.location.href = "/";
        }
    };

    const showPasswordHandler = () => {
        setIsShowPassword(!isShowPassword);
    };

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">Регистрация</p>
                </header>

                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>Регистрация</h3>
                        <Link to="/employee/login" className="auth-page__header-btn">
                            Есть аккаунт?
                        </Link>
                    </div>
                    {group && (
                        <div className="auth-page__info">
                            <div className="auth-page__info-item">
                                <div>
                                    <p>
                                        {group.psych.surname} {group.psych.name}{" "}
                                        {group.psych.patronymic}
                                    </p>
                                    <p className="auth-page__info-desc">Ваш HR / психолог</p>
                                </div>
                            </div>
                            <div className="auth-page__info-item">
                                <p className="auth-page__info-title">Департамент</p>
                                <p>{group.school.name}</p>
                            </div>
                            <div className="auth-page__info-item">
                                <p className="auth-page__info-title">Отдел</p>
                                <p>{group.name}</p>
                            </div>
                        </div>
                    )}
                    <div className="auth-page__input-group auth-page__input-group_row">
                        <div className="input-field">
                            <input
                                id="m"
                                type="radio"
                                name="sex"
                                value="1"
                                checked={form.sex === "1"}
                                onChange={changeHandler}
                            />
                            <label htmlFor="m">Мужской</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="f"
                                type="radio"
                                name="sex"
                                value="2"
                                checked={form.sex === "2"}
                                onChange={changeHandler}
                            />
                            <label htmlFor="f">Женский</label>
                        </div>
                    </div>

                    <div className="auth-page__input-group">
                        <Input
                            id="surname"
                            type="text"
                            name="surname"
                            placeholder="Фамилия"
                            onChange={changeHandler}
                            value={form.surname}
                            isError={incorrectFields.includes("surname")}
                        />
                        <Input
                            id="name"
                            type="text"
                            name="name"
                            onChange={changeHandler}
                            placeholder="Имя"
                            value={form.name}
                            isError={incorrectFields.includes("name")}
                        />
                        <Input
                            id="patronymic"
                            type="text"
                            name="patronymic"
                            onChange={changeHandler}
                            placeholder="Отчество"
                            value={form.patronymic}
                            isError={incorrectFields.includes("patronymic")}
                        />
                        <Input
                            id="birthday"
                            type="text"
                            name="birthday"
                            placeholder="Дата рождения"
                            onChange={changeHandler}
                            value={form.birthday}
                            isError={incorrectFields.includes("birthday")}
                            mask={Date}
                            maskOptions={{
                                min: new Date(1950, 0, 1),
                                max: new Date(),
                                unmask: true,
                            }}
                        />
                        <Input
                            id="login"
                            type="text"
                            name="login"
                            placeholder="Логин"
                            onChange={changeHandler}
                            isError={incorrectFields.includes("login")}
                            value={form.login}
                        />
                        <Input
                            id="password"
                            type={`${isShowPassword ? "text" : "password"}`}
                            name="password"
                            placeholder="Придумайте пароль"
                            onChange={changeHandler}
                            value={form.password}
                            isError={incorrectFields.includes("password")}
                        />
                        <div className="animatedCheckbox animatedCheckbox__password">
                            <div
                                onClick={showPasswordHandler}
                                className={`checkbox ${isShowPassword ? "checked" : ""}`}
                            >
                                <FaCheck style={{ filter: "brightness(0) invert(1)" }} />
                            </div>
                            <p>Показать пароль</p>
                        </div>
                        <p style={{ textAlign: "left", opacity: 0.5 }}>
                            Длина пароля должна быть больше 10-и символов
                        </p>
                    </div>

                    <div className="auth-page__vk-block">
                        <button className="vk-button" onClick={VkAuthHandler} disabled={form.vkId}>
                            {form.vkId ? (
                                <span>Аккаунт ВК привязан</span>
                            ) : (
                                <span>Привязать аккаунт ВК</span>
                            )}
                        </button>

                        <p>
                            Обращаем внимание, что мы не используем личные данные аккаунта в
                            социальных сетях. Для безопасности данных учётной записи нужно
                            использовать 10-символьный пароль, который вы можете случайно забыть.
                            Привязка аккаунта упростит авторизацию на нашей платформе.
                        </p>
                    </div>

                    <div className="landing-contacts__col">
                        <div className="animatedCheckbox">
                            <div
                                style={
                                    firstCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={firstCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                Настоящим я подтверждаю, что ознакомился с{" "}
                                <a href={terms} target="_blank" rel="noreferrer">
                                    Пользовательским соглашением
                                </a>{" "}
                                и принимаю правила использования платформы автоматизации
                                психодиагностики «TestU.online»
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    secondCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={secondCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                Настоящим я подтверждаю, что ознакомился с{" "}
                                <a href={policy} target="_blank" rel="noreferrer">
                                    Политикой обработки персональных данных
                                </a>
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    thirdCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={thirdCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                Настоящим я (или законный представитель субъекта персональных
                                данных) подтверждаю, что даю согласие на обработку персональных
                                данных, указанных в форме для регистрации, для целей заключения и
                                исполнения{" "}
                                <a href={terms} target="_blank" rel="noreferrer">
                                    Пользовательского соглашения
                                </a>{" "}
                                (Правил использования платформы автоматизации психодиагностики
                                «TestU.online»). Срок обработки ограничен сроком действия
                                Пользовательского соглашения.
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    fourthCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={fourthCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                Настоящим я подтверждаю, что даю согласие на обработку моих
                                персональных данных для целей информирования о новых продуктах и
                                услугах, специальных предложениях и различных событиях, связанных с
                                деятельностью компании ООО «ЛССР»
                            </p>
                        </div>
                    </div>

                    <p style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                        Нажимая «Зарегистрироваться», вы подтверждаете, что ознакомились с{" "}
                        <a href={policy} target="_blank" rel="noreferrer">
                            Политикой обработки персональных данных
                        </a>{" "}
                        и согласны с условиями{" "}
                        <a href={terms} target="_blank" rel="noreferrer">
                            Пользовательского соглашения.
                        </a>
                    </p>

                    <button
                        style={
                            firstCheckboxChecked &&
                            secondCheckboxChecked &&
                            thirdCheckboxChecked &&
                            fourthCheckboxChecked
                                ? {}
                                : { background: "gray", boxShadow: "none" }
                        }
                        onClick={register}
                        className="main-btn auth-page__btn"
                    >
                        Зарегистрироваться
                    </button>
                </div>
            </div>
        </div>
    );
};
