import React, { memo, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

import userImg from "static/img/user.svg";

export const LandingMobileMenu = memo(({ user, hideMobileMenu, showPopup }) => {
    useEffect(() => {
        document.body.classList.add("noscroll");

        return () => {
            document.body.classList.remove("noscroll");
        };
    }, []);

    const showPopupHandler = () => {
        hideMobileMenu();
        showPopup();
    };

    return (
        <div className="landing__mobile-menu">
            <nav className="landing__mobile-menu-nav">
                <HashLink
                    onClick={hideMobileMenu}
                    className="landing__button landing__button-primary"
                    to="#landing-about"
                >
                    Наше решение
                </HashLink>
                <HashLink
                    onClick={hideMobileMenu}
                    className="landing__button landing__button-primary"
                    to="#landing-documents"
                >
                    Документы
                </HashLink>
                <HashLink
                    onClick={hideMobileMenu}
                    className="landing__button landing__button-primary"
                    to="#landing-why"
                >
                    Для кого
                </HashLink>
                <HashLink
                    onClick={hideMobileMenu}
                    className="landing__button landing__button-primary"
                    to="#landing-methodology"
                >
                    Методология
                </HashLink>
                <HashLink
                    onClick={hideMobileMenu}
                    className="landing__button landing__button-primary"
                    to="#landing-company"
                >
                    О компании
                </HashLink>
            </nav>

            <div className="landing__mobile-menu-auth">
                {user ? (
                    <Link to="/dashboard" className="landing__button landing__button-profile">
                        <img src={userImg} alt="user" /> Профиль
                    </Link>
                ) : (
                    <button
                        onClick={showPopupHandler}
                        className="landing__button landing__button-secondary"
                    >
                        Хочу внедрить
                    </button>
                )}
            </div>
        </div>
    );
});
