import React, { memo } from "react";
import { Link } from "react-router-dom";

import userImg from "static/img/user.svg";
import heroBlockImg1 from "static/img/landing/hero-block-1.svg";
import heroBlockImg2 from "static/img/landing/hero-block-2.svg";
import heroBlockImg3 from "static/img/landing/hero-block-3.svg";
import heroImg1 from "static/img/landing/hero-img-1.png";
import heroImg2 from "static/img/landing/hero-img-2.png";
import heroImg3 from "static/img/landing/hero-img-3.png";
import heroImg4 from "static/img/landing/hero-img-4.png";

export const LandingHero = memo(({ user, showPopupHandler }) => {
    return (
        <section className="landing__section">
            <div className="landing__hero">
                <div className="landing__hero-title">
                    <h1 className="landing__section-title">
                        Платформа своевременного выявления выгорания сотрудников
                    </h1>
                </div>

                <p className="landing__hero-subtitle">
                    Удобный сервис, который позволяет выстроить прозрачную коммуникацию между
                    сотрудниками и руководством, а также своевременно выявить факторы, влияющие на
                    выгорание специалистов и предупредить их.
                </p>

                <div className="landing__hero-buttons">
                    {user ? (
                        <Link to="/dashboard" className="landing__button landing__button-profile">
                            <img src={userImg} alt="user" /> Профиль
                        </Link>
                    ) : (
                        <>
                            <div className="landing__hero-buttons-auth">
                                <Link
                                    to="/psych/login"
                                    className="landing__button landing__button-secondary"
                                >
                                    Вход для HR / Психолога
                                </Link>
                                <Link
                                    to="/employee/login"
                                    className="landing__button landing__button-secondary"
                                >
                                    Вход для Сотрудника
                                </Link>
                            </div>
                            <div className="landing__hero-buttons-want">
                                <button
                                    onClick={showPopupHandler}
                                    className="landing__button landing__button-secondary"
                                >
                                    Хочу внедрить
                                </button>
                            </div>
                        </>
                    )}
                </div>

                <div className="landing__hero-blocks">
                    <div className="landing__section-block-dark landing__hero-block">
                        <div className="landing__hero-block-icon">
                            <img src={heroBlockImg1} alt="" />
                        </div>
                        <p>
                            Экономия времени на проведение анкетирования сотрудников и выявления
                            поведенческих особенностей
                        </p>
                    </div>
                    <div className="landing__section-block-dark landing__hero-block">
                        <div className="landing__hero-block-icon">
                            <img src={heroBlockImg2} alt="" />
                        </div>
                        <p>
                            Для пользования решением необходим только мобильный телефон и интернет
                        </p>
                    </div>
                    <div className="landing__section-block-dark landing__hero-block">
                        <div className="landing__hero-block-icon">
                            <img src={heroBlockImg3} alt="" />
                        </div>
                        <p>
                            Удобная инфографика результатов тестирования по каждому сотруднику и
                            организации в целом
                        </p>
                    </div>
                </div>

                <img className="landing__hero-image landing__hero-image-1" src={heroImg1} alt="" />
                <img className="landing__hero-image landing__hero-image-2" src={heroImg2} alt="" />
                <img className="landing__hero-image landing__hero-image-3" src={heroImg3} alt="" />
                <img className="landing__hero-image landing__hero-image-4" src={heroImg4} alt="" />
            </div>
        </section>
    );
});
