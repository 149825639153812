import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /schools список школ
 * @apiGroup schools and classes
 * @apiName список школ
 *
 * @apiDescription <p>Страница со списком школ</p>
 * <p>Компонент <code>Schools.js</code> <a href="../client/src/pages/schools/Schools.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings/schools список школ при создании встречи
 * @apiGroup meetings
 * @apiName список школ при создании встречи
 *
 * @apiDescription <p>Страница со списком школ при создании встречи</p>
 * <p>Компонент <code>Schools.js</code> <a href="../client/src/pages/schools/Schools.js">ссылка на файл</a></p>
 */

export const Schools = () => {
    const [schools, setSchools] = useState([]);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get("schools");
            setSchools(data);
        })();
    }, []);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Департамент</p>
                </header>
                <div className="school-page__wrapper">
                    <div className="list">
                        {schools.map((school) => (
                            <Link
                                key={school._id}
                                className="list__item"
                                to={(location) => `${location.pathname}/${school._id}/divisions`}
                            >
                                <p>{school.name}</p>
                                <p className="list__desc">{school.city}</p>
                            </Link>
                        ))}
                    </div>
                </div>
                <Link to={`/departments/create`} className="main-btn">
                    Добавить департамент
                </Link>
            </div>
        </Layout>
    );
};
