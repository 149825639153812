import React from 'react';

import { TestButton } from './TestButton';

export const SelectAnswers = ({
    answers,
    question,
    confirmBtnHandler,
    changeAnswer,
    isConfirmPossible,
    isBackBtnActive,
    backBtnHandler
}) => {
    return (
        <>
            <div className="answers">
                {question.answers.map((elem, index) => {
                    return (
                        <label className="answers__item" key={index}>
                            <input
                                type="checkbox"
                                value={elem.id}
                                checked={answers[index] === elem.id}
                                onChange={(e) => {
                                    changeAnswer(e.target.value, index);
                                }}
                            />
                            <span>{elem.name}</span>
                        </label>
                    );
                })}
            </div>
            <div className="answers-wrapper__btns-block">
                <TestButton
                    isActive={isBackBtnActive}
                    type="back"
                    onClickHandler={backBtnHandler}
                />
                <TestButton
                    isActive={isConfirmPossible}
                    onClickHandler={confirmBtnHandler}
                />
            </div>
        </>
    );
};
