export const problemsList = [
    {
        title: "Анализ стиля жизни",
    },
    {
        title: "Тест самооценки стрессоустойчивости",
    },
    {
        title: "Умеете ли вы быть счастливым?",
    },
    {
        title: "Тест самооценки и тревоги",
    },
    {
        title: "Тест личностной тревожности",
    },
    {
        title: "Причины стресса в вашей работе",
    },
    {
        title: "Шкала ситуативной тревожности",
    },
    {
        title: "МЕТОДИКА «ДИАГНОСТИКА ПРОФЕССИОНАЛЬНОГО «ВЫГОРАНИЯ»",
    },
    {
        title: "Экспресс-оценка выгорания",
    },
    {
        title: "Определение состояния фрустрации",
    },
];

export const problems = [
    {
        title: "Анализ стиля жизни",
        description: "",
        methods: [
            {
                id: "640c49358347c73b146462f1",
                name: "Анализ стиля жизни (Бостонский тест на стрессоустойчивость)",
                type: "Тест",
            },
        ],
    },
    {
        title: "Тест самооценки стрессоустойчивости",
        description: "",
        methods: [
            {
                id: "640f8d5ac87c0c7c9edc9dfa",
                name: "Тест самооценки стрессоустойчивости. С. Коухен и Г. Виллиансон",
                type: "Тест",
            },
        ],
    },
    {
        title: "Умеете ли вы быть счастливым?",
        description: "",
        methods: [
            {
                id: "64135cb372dbb9ff0fc76c0a",
                name: "Умеете ли вы быть счастливым?",
                type: "Тест",
            },
        ],
    },
    {
        title: "Тест самооценки и тревоги",
        description: "",
        methods: [
            {
                id: "64135fc072dbb9ff0fc77284",
                name: "Тест самооценки и тревоги. Цунг",
                type: "Тест",
            },
        ],
    },
    {
        title: "Тест личностной тревожности",
        description: "",
        methods: [
            {
                id: "6413636c72dbb9ff0fc77901",
                name: "Тест личностной тревожности. Спилбергер",
                type: "Тест",
            },
        ],
    },
    {
        title: "Причины стресса в вашей работе",
        description: "",
        methods: [
            {
                id: "64149f5972dbb9ff0fc77fc4",
                name: "Причины стресса в вашей работе",
                type: "Тест",
            },
        ],
    },
    {
        title: "Шкала ситуативной тревожности",
        description: "",
        methods: [
            {
                id: "6414a24672dbb9ff0fc784ff",
                name: "Шкала ситуативной тревожности. Спилбергер",
                type: "Тест",
            },
        ],
    },
    {
        title: "МЕТОДИКА «ДИАГНОСТИКА ПРОФЕССИОНАЛЬНОГО «ВЫГОРАНИЯ»",
        description: "",
        methods: [
            {
                id: "6414a80672dbb9ff0fc78dfb",
                name: "МЕТОДИКА «ДИАГНОСТИКА ПРОФЕССИОНАЛЬНОГО «ВЫГОРАНИЯ». К. Маслач, С.Джексон",
                type: "Тест",
            },
        ],
    },
    {
        title: "Экспресс-оценка выгорания",
        description: "",
        methods: [
            {
                id: "6414b09172dbb9ff0fc7995d",
                name: "Экспресс-оценка выгорания. В. Каппони, Т. Новак.",
                type: "Тест",
            },
        ],
    },
    {
        title: "Определение состояния фрустрации",
        description: "",
        methods: [
            {
                id: "6414b31572dbb9ff0fc79c50",
                name: "Определение состояния фрустрации. В. В. Бойко",
                type: "Тест",
            },
        ],
    },
];
