import React from 'react'

export const Notice = ({ text, closeHandler }) => {
  return (
    <div className="notice">
      <div className="notice__content">
        <p>{text}</p>
        <button onClick={closeHandler}>Понятно</button>
      </div>
    </div>
  )
}