import React, { memo } from "react";

import QR from "static/img/landing/qr.svg";
import check from "static/img/landing/check.svg";
import blocks from "static/img/landing/blocks.svg";
import phone from "static/img/phone.png";
import diagram from "static/img/landing/diagram.svg";
import feather from "static/img/landing/feather.svg";
import attention from "static/img/landing/attention.svg";
import aboutInfo from "static/img/landing/about-info.svg";

export const LandingAbout = memo(({ showPopupHandler }) => {
    return (
        <section className="landing__section" id="landing-about">
            <div className="landing__about">
                <h2 className="landing__section-title landing__about-title">О продукте</h2>

                <div className="landing__about-content">
                    <div className="landing__about-content-left">
                        <div className="landing__about-content-block landing__about-content-block--left">
                            <img src={QR} alt="" />
                            <p>Добавление сотрудников в систему через QR код</p>
                        </div>
                        <div className="landing__about-content-block landing__about-content-block--left">
                            <img src={check} alt="" />
                            <p>Комплекс методик по системному выявлению выгорания</p>
                        </div>
                        <div className="landing__about-content-block landing__about-content-block--left">
                            <img src={blocks} alt="" />
                            <p>
                                Автоматическая обработка результатов тестирования и формирование
                                отчетов
                            </p>
                        </div>
                    </div>

                    <div className="landing__about-content-center">
                        <img src={phone} alt="" />
                    </div>

                    <div className="landing__about-content-right">
                        <div className="landing__about-content-block landing__about-content-block--right">
                            <img src={diagram} alt="" />
                            <p>Конструктор опросов для проведения дополнительного анкетирования</p>
                        </div>
                        <div className="landing__about-content-block landing__about-content-block--right">
                            <img src={attention} alt="" />
                            <p>
                                Инструмент для сотрудника заявить о своей проблеме по нажатию одной
                                кнопки, в том числе анонимно
                            </p>
                        </div>
                        <div className="landing__about-content-block landing__about-content-block--right">
                            <img src={feather} alt="" />
                            <p>
                                Возможность хранить на платформе рекомендации и заметки для каждого
                                сотрудника, назначать встречи, собирать обратную связь о качестве
                                корпоративной культуры
                            </p>
                        </div>
                    </div>

                    <div className="landing__about-button">
                        <button
                            onClick={showPopupHandler}
                            className="landing__button landing__button-secondary"
                        >
                            Хочу внедрить
                        </button>
                    </div>
                </div>

                <div className="landing__about-block landing__section-block-dark">
                    <img src={aboutInfo} alt="" />
                    <div className="landing__about-block-text">
                        <p>
                            Наша платформа является коммуникационной и представляет собой удобную IT
                            оболочку для компьютеризации любой методики, опросов, анкетирований.
                        </p>
                        <p>
                            Под ваш запрос мы можем подобрать валидированный набор методик, которые
                            актуальны для практики применения в вашей организации.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
});
