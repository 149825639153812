import React from "react";

import close from "../static/img/ic-close.svg";
import "../static/scss/sureAlert.scss";

export const SureAlert = (props) => {
    return (
        <div className="sureAlert">
            <div className="sureAlert__inner">
                <h3>Предупреждение</h3>

                {props.type === "createTest" && (
                    <p>
                        Вы точно уверены, что хотите назначить данный тест?
                        <br />
                        <br />
                        Отменить это действие можно будет обратившись к нам по почте
                        help@testu.online
                    </p>
                )}

                {props.type === "createMeeting" && (
                    <p>
                        Вы точно уверены, что хотите назначить данную встречу?
                        <br />
                        <br />
                        Отменить это действие можно будет обратившись к нам по почте
                        help@testu.online
                    </p>
                )}

                {props.type === "createNote" && (
                    <p>
                        Вы точно уверены, что хотите добавить данную заметку?
                        <br />
                        <br />
                        Отменить это действие можно будет обратившись к нам по почте
                        help@testu.online
                    </p>
                )}

                {props.type === "createAdvice" && (
                    <p>
                        Вы точно уверены, что хотите добавить данную рекомендацию?
                        <br />
                        <br />
                        Отменить это действие можно будет обратившись к нам по почте
                        help@testu.online
                    </p>
                )}

                {props.type === "wantTalk" && (
                    <p>
                        Вы можете пользоваться данной функцией в случае, если хотите поговорить с
                        психологом и Вам было бы не совсем комфортно заявить специалисту об этом
                        очно. Если Вам есть чем поделиться не переживайте, так бывает у всех.
                        <br />
                        <br />
                        Когда Вы нажмете на кнопку "Запрос на разговор" психолог самостоятельно
                        назначит вам встречу и сделает это так, чтобы всё общение происходило
                        незаметно от окружающих, с полной конфиденциальностью.
                    </p>
                )}

                <button onClick={props.handleSubmit} className="send">
                    {props.submitText ? <>{props.submitText}</> : "Отправить"}
                </button>

                <button onClick={props.handleClose} className="close">
                    <img alt="" src={close} />
                </button>
            </div>
        </div>
    );
};
