import React, { useCallback } from "react";
import { Link } from "react-router-dom";

export const History = ({ history }) => {
    const formatDate = useCallback((date) => {
        return new Date(date).toLocaleString("ru", {
            hour: "numeric",
            minute: "numeric",
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    }, []);

    const getUrl = () => {
        if (history.type === "advices") return `/advices?employeeId=${history.pupils[0]._id}`;
        if (history.type === "notes") return `/notes?employeeId=${history.pupils[0]._id}`;
        if (history.type === "meetings") return `/employees/${history.pupils[0]?._id}/meetings`;
        if (history.type === "tests") return `/departments/${history.schoolId}/divisions`;
        return "/";
    };

    return (
        <Link to={getUrl} className="history">
            <p className="history__date">{formatDate(history.date)}</p>
            <div className="history__wrapper">
                <span className={`history__tag history__tag_${history.type}`}>
                    {history.tag || history.type}
                </span>
                <p className="history__title">{history.title}</p>
                <p className="history__desc" dangerouslySetInnerHTML={{ __html: history.desc }} />
                <p className="history__pupils">{history.pupils.length} сотрудников</p>
            </div>
        </Link>
    );
};
