import React, { memo } from "react";

import terms from "static/docs/LSSR_terms_of_use.pdf";
import policy from "static/docs/policy.pdf";
import logo from "static/img/landing/logo.svg";
import sk from "static/img/sk.png";
import planet from "static/img/landing/planet.svg";

export const LandingFooter = memo(() => {
    return (
        <footer className="landing__footer">
            <div className="landing__footer-content">
                <div className="landing__footer-images">
                    <img className="landing__footer-images-logo" src={logo} alt="TesU.online" />
                    <a href="https://www.sk.ru" target="_blank" rel="noreferrer">
                        <img className="landing__footer-images-sk" src={sk} alt="Sk участник" />
                    </a>
                </div>

                <div className="landing__footer-center">
                    <p className="landing__footer-center-first">
                        <a href={terms} target="_blank" rel="noreferrer">
                            Условия пользования
                        </a>
                        <a href={policy} target="_blank" rel="noreferrer">
                            Политика конфиденциальности
                        </a>
                    </p>
                    <p>
                        Вы можете задать нам любой вопрос: <br />
                        <a href="mailto:hr@testu.online">hr@testu.online</a>
                    </p>
                    <p>{new Date().getFullYear()} ООО “ЛССР”. Все права защищены</p>
                </div>

                <div className="landing__footer-right">
                    <img src={planet} alt="" />
                    <div>
                        <p>
                            ПО распространяется в виде интернет-сервиса, специальные действия по
                            установке ПО на стороне пользователя не требуются.
                        </p>
                        <p>
                            ПО возможно использовать на условиях безвозмездного лицензионного
                            договора.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
});
