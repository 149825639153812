import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FaPen } from "react-icons/fa";
import { FiArchive } from "react-icons/fi";

import { hideNotice, showNotice } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Notice } from "components/Notice";
import trashImg from "../static/img/trash.svg";

/**
 * @api {get} /classes/:id/pupils список учеников класса
 * @apiGroup schools and classes
 * @apiName список учеников класса
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница со списком учеников класса</p>
 * <p>Компонент <code>Pupils.js</code> <a href="../client/src/pages/Pupils.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings/classes/:id/pupils список учеников при создании встречи
 * @apiGroup meetings
 * @apiName список учеников при создании встречи
 *
 * @apiParam {String} id ID класса
 *
 * @apiDescription <p>Страница со списком учеников при создании встречи</p>
 * <p>Компонент <code>Pupils.js</code> <a href="../client/src/pages/Pupils.js">ссылка на файл</a></p>
 */

const styles = {
    button: {
        display: "flex",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        top: "1rem",
        right: "1rem",
        width: "2rem",
        height: "2rem",
        background: "#f4f7f9",
        borderRadius: "7px",
    },
    h4: {
        color: "#122443",
        fontSize: "20px",
        letterSpacing: "0.4px",
        marginBottom: "1rem",
        fontWeight: "normal",
    },
    list: {
        flexGrow: "initial",
        marginBottom: "3rem",
    },
};

export const Pupils = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classId = useParams().id;
    const { notice } = useSelector((state) => state.app);
    const [pupils, setPupils] = useState([]);

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}/pupils`);
                setPupils(data);
            })();
        }
    }, [classId]);

    const hideNoticeHandler = useCallback(() => {
        dispatch(hideNotice());
    }, [dispatch]);

    const archive = async (e) => {
        e.stopPropagation();
        const pupil = pupils.filter((p) => p._id === e.currentTarget.id)[0];

        if (pupil && window.confirm("Подтвердите внесение изменений")) {
            try {
                const { data } = await axios.put(`pupil/${e.currentTarget.id}`, {
                    isArchive: !pupil.isArchive,
                    classId,
                });

                setPupils(pupils.map((pupil) => (pupil._id === data._id ? data : pupil)));
            } catch (err) {
                const message =
                    err.response?.data?.message ||
                    "Что-то пошло не так, попробуйте обновить страницу";
                dispatch(showNotice(message));
            }
        }
    };

    return (
        <Layout>
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{pupils[0]?.class.name}</p>
                    {classId && (
                        <Link
                            to={`/divisions/${classId}`}
                            className="icon-btn page__icon-btn page__icon-btn_right"
                        >
                            <FaPen />
                        </Link>
                    )}
                </header>

                <div className="school-page__wrapper">
                    <div className="list gesturedZone" style={styles.list}>
                        {pupils
                            .filter((p) => !p.isArchive)
                            .map((pupil) => (
                                <div
                                    key={pupil._id}
                                    style={{
                                        position: "relative",
                                    }}
                                    onClick={() => {
                                        if (
                                            window.location.href.indexOf("/meetings/divisions/") !==
                                            -1
                                        ) {
                                            history.push(`/employees/${pupil._id}/meetings/create`);
                                        } else {
                                            history.push(`/employees/${pupil._id}`);
                                        }
                                    }}
                                    className="list__item"
                                >
                                    <p>
                                        {pupil.surname} {pupil.name}
                                    </p>
                                    <p className="list__desc">
                                        {pupil.psych?.surname} {pupil.psych?.name}{" "}
                                        {pupil.psych?.patronymic}
                                    </p>
                                    <button id={pupil._id} onClick={archive} style={styles.button}>
                                        <FiArchive style={{ fontSize: "16px" }} />
                                    </button>
                                    <div className="deleteStudent">
                                        <img alt="" src={trashImg} />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <h4 style={styles.h4}>Архив</h4>
                    <div className="list">
                        {pupils
                            .filter((p) => p.isArchive)
                            .map((pupil) => (
                                <div
                                    key={pupil._id}
                                    style={{
                                        position: "relative",
                                    }}
                                    onClick={() => {
                                        if (
                                            window.location.href.indexOf("/meetings/divisions/") !==
                                            -1
                                        ) {
                                            history.push(`/employees/${pupil._id}/meetings/create`);
                                        } else {
                                            history.push(`/employees/${pupil._id}`);
                                        }
                                    }}
                                    className="list__item"
                                >
                                    <p>
                                        {pupil.surname} {pupil.name}
                                    </p>
                                    <p className="list__desc">
                                        {pupil.psych?.surname} {pupil.psych?.name}{" "}
                                        {pupil.psych?.patronymic}
                                    </p>
                                    <button id={pupil._id} onClick={archive} style={styles.button}>
                                        <FiArchive style={{ fontSize: "16px" }} />
                                    </button>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
