import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";

export const CreateNote = ({ createHandler, closeHandler }) => {
    const [form, setForm] = useState({ title: "", text: "" });
    const [isSureAlert, setIsSureAlert] = useState(false);

    function onSendClick() {
        setIsSureAlert(true);
    }

    function onCloseClick() {
        setIsSureAlert(false);
    }

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={closeHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">Заметки</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>Написать заметку</h3>
                    <div className="input-field input-field_yellow">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="title"
                            name="title"
                            value={form.title}
                            placeholder="Заголовок заметки"
                            onChange={changeHandler}
                        />
                        <label htmlFor="number">Заголовок</label>
                    </div>
                    <div className="input-field input-field_yellow">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="text"
                            name="text"
                            value={form.text}
                            onChange={changeHandler}
                            placeholder="Подробности заметки"
                        />
                        <label htmlFor="letter">Подробности</label>
                    </div>
                </div>

                <button
                    onClick={onSendClick}
                    disabled={!form.title || !form.text}
                    className="main-btn main-btn_yellow"
                >
                    Отправить
                </button>
            </div>

            {isSureAlert && (
                <SureAlert
                    type="createNote"
                    handleClose={onCloseClick}
                    handleSubmit={() => {
                        createHandler(form);
                        onCloseClick();
                    }}
                />
            )}
        </Layout>
    );
};
