import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { IMaskInput } from "react-imask";
import { FaCheck } from "react-icons/fa";

import { hideNotice, showAlert, showNotice } from "redux/actions/setAppActions";
import { validateEmail, validatePhone } from "helpers/validators";
import { Alert } from "../Alert";
import { Notice } from "../Notice";
import policy from "../../static/docs/policy.pdf";

const initForm = {
    name: "",
    surname: "",
    patronymic: "",
    phone: "",
    email: "",
    company: "",
    message: "",
};

export const LandingContactForm = memo(() => {
    const dispatch = useDispatch();
    const { alert, notice } = useSelector((state) => state.app);
    const [form, setForm] = useState(initForm);
    const [step, setStep] = useState(1);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const changeHandler = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const firstCheckboxHandler = () => {
        setFirstCheckboxChecked(!firstCheckboxChecked);
    };

    const secondCheckboxHandler = () => {
        setSecondCheckboxChecked(!secondCheckboxChecked);
    };

    const sendForm = async () => {
        try {
            await axios.post("requests/create", { ...form });
            dispatch(showNotice("Заявка успешно отправлена"));
            setIsSuccess(true);
        } catch (err) {
            if (err.response?.data?.message) {
                dispatch(showNotice(err.response.data.message));
            } else {
                dispatch(showNotice(err.message));
            }
        }
    };

    const hideNoticeHandler = () => {
        dispatch(hideNotice());

        if (isSuccess) {
            setStep(1);
            setForm(initForm);
            setIsSuccess(false);
        }
    };

    const forwardHandler = async () => {
        if (step === 1 && form.name && form.surname) {
            setStep(step + 1);
        } else if (step === 2 && form.phone && form.email) {
            if (!validatePhone(form.phone)) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: "Введите корректный телефон",
                    })
                );
            } else if (!validateEmail(form.email)) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: "Введите корректный email",
                    })
                );
            } else {
                setStep(step + 1);
            }
        } else if (step === 3 && form.company) {
            setStep(step + 1);
        } else if (step === 4 && form.message) {
            await sendForm(form);
        } else {
            dispatch(
                showAlert({
                    type: "error",
                    text: "Заполните все поля",
                })
            );
        }
    };

    return (
        <div className="landing-contacts__form">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <div className="landing-contacts__form-steps">
                <span>Шаг {step}</span>
                <span>из 4</span>
            </div>

            {step === 1 && (
                <div className="landing-contacts__input-group">
                    <div className="landing-contacts__input-field">
                        <label htmlFor="name">Ваше Имя</label>
                        <input
                            placeholder="Имя"
                            id="name"
                            type="text"
                            name="name"
                            onChange={changeHandler}
                            value={form.name}
                        />
                    </div>
                    <div className="landing-contacts__input-field">
                        <label htmlFor="surname">Ваша Фамилия</label>
                        <input
                            placeholder="Фамилия"
                            id="surname"
                            type="text"
                            name="surname"
                            onChange={changeHandler}
                            value={form.surname}
                        />
                    </div>
                    <div className="landing-contacts__input-field">
                        <label htmlFor="surname">Ваше отчество</label>
                        <input
                            placeholder="Отчество"
                            id="patronymic"
                            type="text"
                            name="patronymic"
                            onChange={changeHandler}
                            value={form.patronymic}
                        />
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className="landing-contacts__input-group">
                    <div className="landing-contacts__input-field">
                        <label htmlFor="phone">Номер телефона</label>
                        <IMaskInput
                            placeholder="Номер телефона"
                            id="phone"
                            type="text"
                            name="phone"
                            onChange={changeHandler}
                            value={form.phone}
                            mask="+{7}(000)000-00-00"
                        />
                    </div>
                    <div className="landing-contacts__input-field">
                        <label htmlFor="email">Эл. почта</label>
                        <input
                            placeholder="Эл. почта"
                            id="email"
                            type="text"
                            name="email"
                            onChange={changeHandler}
                            value={form.email}
                        />
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className="landing-contacts__input-group">
                    <div className="landing-contacts__input-field">
                        <label htmlFor="company">Наименование организации</label>
                        <input
                            placeholder="ООО “Фирма”"
                            id="company"
                            type="text"
                            name="company"
                            onChange={changeHandler}
                            value={form.company}
                        />
                    </div>
                </div>
            )}

            {step === 4 && (
                <div className="landing-contacts__input-group">
                    <div className="landing-contacts__input-field">
                        <label htmlFor="message">Запрос</label>
                        <textarea
                            placeholder="Опишите проблему или вопрос"
                            id="message"
                            // type="text"
                            name="message"
                            rows={4}
                            onChange={changeHandler}
                            value={form.message}
                        />
                    </div>
                    <div className="landing-contacts__col">
                        <div className="animatedCheckbox landing__contacts-policy">
                            <div
                                style={
                                    firstCheckboxChecked
                                        ? {
                                              backgroundColor: "#2330BA",
                                          }
                                        : {}
                                }
                                onClick={firstCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                Настоящим я подтверждаю, что даю согласие на{" "}
                                <a href={policy} download="download">
                                    обработку моих персональных данных
                                </a>{" "}
                                для целей заключения и исполнения Пользовательского соглашения*
                            </p>
                        </div>
                        <div className="animatedCheckbox landing__contacts-policy">
                            <div
                                style={
                                    secondCheckboxChecked
                                        ? {
                                              backgroundColor: "#2330BA",
                                          }
                                        : {}
                                }
                                onClick={secondCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                Настоящим я подтверждаю, что даю согласие на{" "}
                                <a href={policy} download="download">
                                    обработку моих персональных данных
                                </a>{" "}
                                для целей информирования о новых продуктах и услугах, специальных
                                предложениях и различных событиях, связанных с деятельностью
                                компании ООО «ЛССР»
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div>
                <div className="landing-contacts__form-note">
                    Ваши данные находятся в полной сохранности. Сайт использует сертификат
                    безопасности.
                </div>
                <div className="landing-contacts__form-btns">
                    {step !== 1 && (
                        <button
                            onClick={() => setStep(step - 1)}
                            className="landing-contacts__form-btn landing-contacts__form-btn_back"
                        >
                            Назад
                        </button>
                    )}
                    {(step !== 4 || (firstCheckboxChecked && secondCheckboxChecked)) && (
                        <button
                            onClick={forwardHandler}
                            className="landing-contacts__form-btn landing-contacts__form-btn_forward"
                        >
                            {step === 4 ? "Отправить" : "Далее"}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
});
