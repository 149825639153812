import React, { useEffect, useState, useRef } from 'react'
import {Chart} from "chart.js";

import {Layout} from "../Layout";

export const Diagram = ({handler, conditionName, rows}) => {
  const canvasRef = useRef(null)
  const [row, setRow] = useState(null)
  const [data, setData] = useState(null)
  const [colors, setColors] = useState(null)
  const [radar, setRadar] = useState(null)


  useEffect(() => {
    setRow(rows[0])
  }, [rows])

  useEffect(() => {
    const newLabels = []
    const newData = []
    const colors = []

    for (let item of rows) {
      newLabels.push('•')
      newData.push(Number(item.score.replace(',', '.')))

      if (item.norm) {
        colors.push(row === item ? 'rgba(82,194,43, 1)' : 'rgba(82,194,43, 0.5)')
      } else {
        colors.push(row === item ? 'rgba(255,90,82, 1)' : 'rgba(255,90,82, 0.5)')
      }
    }

    const data = {
      labels: newLabels,
      datasets: [
        {
          fill: false,
          data: newData,
          borderWidth: 2,
          borderColor: '#52C22B'
        },
      ],
    }
    setData(data)
    setColors(colors)


  }, [rows, row])

  useEffect(() => {
    const canvas = canvasRef.current

    const options = {
      maintainAspectRatio: false,
      scale: {
        pointLabels: {
          fontSize: 45,
          fontColor: colors,
        },
        ticks: {
          min: 0,
          stepSize: 1,
          display: false
        },
      },

      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      legend: {display: false},
      tooltips: {enabled: false},
      // onClick: function (evt, element) {
      //     if (!element.length) return
      //     setRow(rows[element[0]._index])
      // },
    }

    if (canvas) {
      const context = canvas.getContext('2d')
      const myRadar = new Chart(context, {
        type: 'radar',
        data: data,
        options: options,
      })
      myRadar.ctx.minHeight = 275
      // myRadar.ctx.width = '100%'

      setRadar(myRadar)
    }

  }, [data, rows, colors])

  const canvasHandler = (e) => {
    var helpers = Chart.helpers
    var scale = radar.scale
    var opts = scale.options
    var tickOpts = opts.ticks

    // Position of click relative to canvas.
    var mouseX = e.nativeEvent.offsetX
    var mouseY = e.nativeEvent.offsetY

    var labelPadding = 5 // number pixels to expand label bounding box by

    var tickBackdropHeight = (tickOpts.display && opts.display) ?
      helpers.valueOrDefault(tickOpts.fontSize, Chart.defaults.global.defaultFontSize)
      + 5 : 0
    var outerDistance = scale.getDistanceFromCenterForValue(opts.ticks.reverse ? scale.min : scale.max)
    for (var i = 0; i < scale.pointLabels.length; i++) {
      // Extra spacing for top value due to axis labels
      var extra = (i === 0 ? tickBackdropHeight / 2 : 0)
      var pointLabelPosition = scale.getPointPosition(i, outerDistance + extra + 5)

      var plSize = scale._pointLabelSizes[i]

      // get label textAlign info
      var angleRadians = scale.getIndexAngle(i)
      var angle = helpers.toDegrees(angleRadians)
      var textAlign = 'right'
      if (angle === 0 || angle === 180) {
        textAlign = 'center'
      } else if (angle < 180) {
        textAlign = 'left'
      }

      var verticalTextOffset = 0
      if (angle === 90 || angle === 270) {
        verticalTextOffset = plSize.h / 2
      } else if (angle > 270 || angle < 90) {
        verticalTextOffset = plSize.h
      }

      var labelTop = pointLabelPosition.y - verticalTextOffset - labelPadding;
      var labelHeight = 2 * labelPadding + plSize.h
      var labelBottom = labelTop + labelHeight

      var labelWidth = plSize.w + 2 * labelPadding
      var labelLeft
      switch (textAlign) {
        case 'center':
          labelLeft = pointLabelPosition.x - labelWidth / 2
          break
        case 'left':
          labelLeft = pointLabelPosition.x - labelPadding
          break;
        case 'right':
          labelLeft = pointLabelPosition.x - labelWidth + labelPadding
          break
        default:
          console.log('ERROR: unknown textAlign ' + textAlign)
      }
      var labelRight = labelLeft + labelWidth

      if (mouseX >= labelLeft && mouseX <= labelRight && mouseY <= labelBottom && mouseY >= labelTop) {
        setRow(rows[i])
        break
      }


    }
  }

  if (!row) return null

  return (
    <Layout>
      <div className='page'>
        <header className="page__header">
          <button onClick={handler}
                  className="icon-btn page__icon-btn page__icon-btn_left icon-btn_close"></button>
          <p className="page__title">Статистика</p>
        </header>

        <div className="diagram">
          <p className='diagram__title'>{conditionName}</p>

          <div className="chart-container"
               style={{'minHeight': `278px`, 'width': '100%', 'position': 'relative'}}>
            <canvas ref={canvasRef} onClick={canvasHandler}/>
          </div>

          <p className="diagram__condition-name">{row.name}</p>

          <div className="diagram__details">
            <p className="diagram__details-key">{row.method}</p>
            <p className="diagram__details-value">{row.score}</p>
          </div>

          <div className="diagram__details">
            <p className="diagram__details-key">Показатель:</p>
            <span style={{background: row.color}} className="solution-result__mark">{row.title}</span>
          </div>


          <p className="diagram__condition-name">Интерпретация:</p>
          <p>{row.interpretation}</p>
          <p className="diagram__condition-name">Рекомендации:</p>
          <p>{row.recommendation}</p>

        </div>
      </div>
    </Layout>
  )
}