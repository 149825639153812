import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAlarms } from "redux/actions/setAlarmActions";
import { getMeetings } from "redux/actions/setMeetingActions";
import { getTestsCountByPsych } from "redux/actions/setTestActions";
import { getPupils } from "redux/actions/setPupilsAction";
import { getSchools } from "redux/actions/setSchoolActions";
import { Loader } from "components/Loader";

export const PsychProfile = ({ loading }) => {
    const dispatch = useDispatch();
    const { alarms } = useSelector((state) => state.alarm);
    const { schools } = useSelector((state) => state.school);
    const { meetings } = useSelector((state) => state.meeting);
    const { testsCount } = useSelector((state) => state.test);
    const { pupilsData } = useSelector((state) => state.pupils);
    const [riskPupilsCount, setRiskPupilsCount] = useState(0);

    useEffect(() => {
        dispatch(getMeetings());
        dispatch(getTestsCountByPsych());
        dispatch(getPupils());
        dispatch(getSchools());
        dispatch(getAlarms());
    }, [dispatch]);

    useEffect(() => {
        if (pupilsData.length) {
            const pupilsWithRisks = pupilsData.filter(({ categories }) => categories.length);
            setRiskPupilsCount(pupilsWithRisks.length);
        }
    }, [pupilsData]);

    return (
        <div className="profile-page__entities">
            <Link
                to="/alarms"
                className={`profile-page__psych-alarm 
                        ${alarms[0] && !alarms[0].readAt && "profile-page__psych-alarm_notice"}`}
            >
                <div>
                    <p>Запросы о помощи</p>
                    <p>Посмотрите, кому нужна поддержка</p>
                </div>
                <span>{alarms.length}</span>
            </Link>

            {loading ? (
                <Loader />
            ) : (
                <div className="profile-page__entities-wrapper">
                    <Link
                        to="/departments"
                        className={`entity ${!!schools.length && "entity_school"}`}
                    >
                        <p className={`entity__title ${!!schools.length && "entity__title_white"}`}>
                            Департаменты
                        </p>
                        {!schools.length && (
                            <p className="entity__description">Добавьте департамент</p>
                        )}
                        <span
                            className={`entity__extra ${
                                !schools.length && "entity__extra_psych-null"
                            }`}
                        >
                            {!!schools.length && schools.length}
                        </span>
                    </Link>
                    <Link
                        to="/meetings"
                        className={`entity ${meetings.length ? "entity_meeting" : null}`}
                    >
                        <p
                            className={`entity__title ${
                                meetings.length ? "entity__title_white" : null
                            }`}
                        >
                            Встречи
                        </p>
                        {!meetings.length && (
                            <p className="entity__description">Добавьте встречу</p>
                        )}
                        <span
                            className={`entity__extra ${
                                !meetings.length ? "entity__extra_psych-null" : null
                            }`}
                        >
                            {meetings.length ? meetings.length : null}
                        </span>
                    </Link>
                    <Link
                        to="/statistic/departments"
                        className={`entity ${!!testsCount && "entity_statistic"}`}
                    >
                        <p className={`entity__title ${testsCount ? "entity__title_white" : null}`}>
                            Результаты психодиагностики
                        </p>
                        {!testsCount && (
                            <p className="entity__description">Нет данных для анализа</p>
                        )}
                        {!!testsCount && (
                            <span className="entity__extra entity__extra_statistic"></span>
                        )}
                    </Link>
                    <Link to="/workWithMethods/problems" className="entity entity_workWithMethods">
                        <p className="entity__title entity__title_white">Работа с методиками</p>

                        <span className="entity__extra entity__extra_workWithMethods"></span>
                    </Link>
                    <Link
                        to="/riskGroups"
                        className={`entity ${!!riskPupilsCount && "entity_riskGroups"}`}
                    >
                        <p
                            className={`entity__title ${
                                riskPupilsCount ? "entity__title_white" : null
                            }`}
                        >
                            Группы риска
                        </p>
                        <span
                            className={`entity__extra ${
                                !riskPupilsCount && "entity__extra_psych-null"
                            }`}
                        >
                            {!!riskPupilsCount && riskPupilsCount}
                        </span>
                    </Link>
                    <Link to="/instruction" className="entity entity_instruction">
                        <p className="entity__title entity__title_white">Инструкция</p>
                    </Link>
                </div>
            )}
        </div>
    );
};
