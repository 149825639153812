import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showAlert } from "redux/actions/setAppActions";
import { LandingPopupForm } from "components/landing/LandingPopupForm";
import closeIcon from "static/img/landing/close.svg";
import image from "static/img/landing/company-img.png";

export const LandingPopup = memo(({ hidePopupHandler }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);

    useEffect(() => {
        document.body.classList.add("noscroll");

        return () => {
            document.body.classList.remove("noscroll");
        };
    }, []);

    const changeStepHandler = () => {
        if (step === 1) {
            setStep(2);
        } else if (step === 3) {
            hidePopupHandler();
        }
    };

    const sendForm = async (form) => {
        try {
            await axios.post("psych/register", { ...form });
            setStep(3);
        } catch (e) {
            dispatch(
                showAlert({
                    type: "error",
                    text: "Ошибка при отправке заявки",
                })
            );
        }
    };

    return (
        <div className="landing__popup">
            <div className="landing__popup-inner">
                <button className="landing__popup-close-btn" onClick={hidePopupHandler}>
                    <img src={closeIcon} alt="close button" />
                </button>

                <div className="landing__popup-body">
                    <div className="landing__section-block-dark landing__popup-image">
                        <img src={image} alt="" />
                    </div>

                    <div className="landing__popup-content">
                        {step === 1 && (
                            <div className="landing__popup-content-flex">
                                <div>
                                    <h2 className="landing__popup-content-title">
                                        Спасибо за интерес к нашему продукту!
                                    </h2>
                                    <p className="landing__popup-content-subtitle">
                                        Мы предлагаем Вам бесплатно попробовать наше решение для
                                        системного выявления проблем выгорания сотрудников и
                                        получения обратной связи от коллектива.
                                    </p>

                                    <div className="landing__popup-content-proposal">
                                        <div className="landing__popup-content-proposal-item">
                                            <span className="landing__rounded-number">01</span>
                                            <p>
                                                Комплекс методик по системному выявлению проблем
                                                стресс-менеджмента
                                            </p>
                                        </div>
                                        <div className="landing__popup-content-proposal-item">
                                            <span className="landing__rounded-number">02</span>
                                            <p>
                                                Цифровое пространство для коммуникации с
                                                сотрудниками и получению обратной связи
                                            </p>
                                        </div>
                                        <div className="landing__popup-content-proposal-item">
                                            <span className="landing__rounded-number">03</span>
                                            <p>
                                                Цифровое пространство для коммуникации с
                                                сотрудниками и получению обратной связи
                                            </p>
                                        </div>
                                        <div className="landing__popup-content-proposal-item">
                                            <span className="landing__rounded-number">04</span>
                                            <p>
                                                Ведение заметок по каждому из работников, функционал
                                                назначения встреч и рекомендаций, кнопка «Хочу
                                                поговорить» для каждого работника
                                            </p>
                                        </div>
                                        <div className="landing__popup-content-proposal-item">
                                            <span className="landing__rounded-number">05</span>
                                            <p>Аналитика в реальном времени по всему коллективу</p>
                                        </div>
                                        <div className="landing__popup-content-proposal-item">
                                            <span className="landing__rounded-number">06</span>
                                            <p>
                                                Возможность проводить массовые опросы в течении 10
                                                минут с получением результатов и возможностью
                                                анонимного прохождения сотрудниками
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="landing__button landing__button-secondary landing__popup-content-button"
                                    onClick={changeStepHandler}
                                >
                                    Отправить заявку
                                </button>
                            </div>
                        )}

                        {step === 2 && <LandingPopupForm sendForm={sendForm} />}

                        {step === 3 && (
                            <div className="landing__popup-content-flex">
                                <div>
                                    <h2 className="landing__popup-content-title">
                                        Спасибо за Ваше время на заполнение заявки!
                                    </h2>
                                    <p className="landing__popup-content-subtitle">
                                        Мы обязательно свяжемся с Вами и предоставим доступы к
                                        Платформе
                                    </p>
                                </div>

                                <button
                                    className="landing__button landing__button-secondary landing__popup-content-button"
                                    onClick={changeStepHandler}
                                >
                                    Понятно
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
