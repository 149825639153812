import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { RegisterPupil } from "pages/RegisterPupil";
import { Schools } from "pages/schools/Schools";
import { SchoolForm } from "pages/schools/SchoolForm";
import { Classes } from "pages/classes/Classes";
import { ClassForm } from "pages/classes/ClassForm";
import { Code } from "pages/Code";
import { Pupils } from "pages/Pupils";
import { StatisticSchools } from "pages/statistic/StatisticSchools";
import { StatisticClasses } from "pages/statistic/StatisticClasses";
import { StatisticPupils } from "pages/statistic/StatisticPupils";
import { StatisticPupil } from "pages/statistic/StatisticPupil";
import { StatisticClassesFilters } from "pages/statistic/StatisticClassesFilters";
import { Meetings } from "pages/meetings/Meetings";
import { MeetingForm } from "pages/meetings/MeetingForm";
import { TestsPage } from "pages/TestsPage";
import { SolutionPage } from "pages/SolutionPage";
import { TestPage } from "pages/TestPage";
import { ProfilePage } from "pages/ProfilePage";
import { CreateTestPage } from "pages/CreateTestPage";
import { SettingsPage } from "pages/SettingsPage";
import { SecurityPage } from "pages/SecurityPage";
import { LandingPage } from "pages/LandingPage";
import { PupilPage } from "pages/PupilPage";
import { AdvicesPage } from "pages/AdvicesPage";
import { NotesPage } from "pages/NotesPage";
import { AlarmsPage } from "pages/AlarmsPage";
import { LosePassPage } from "pages/LosePassPage";
import { CategoriesPage } from "pages/CategoriesPage";
import { CreateTestFilters } from "pages/CreateTestFilters";
import { InstructionPage } from "pages/InstructionPage";
import { RiskGroups } from "pages/RiskGroups";
import ProblemsPage from "pages/ProblemsPage";
import ProblemPage from "pages/ProblemPage";
import { Loader } from "components/Loader";

const Login = lazy(() => import("pages/Login"));

function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route path="/" component={LandingPage} exact />
                    <Route path="/dashboard" component={ProfilePage} exact />
                    <Route path="/psych/login" render={() => <Login role="psych" />} />
                    <Route path="/psych/lose" render={() => <LosePassPage role="psych" />} />
                    <Route path="/employee/login" render={() => <Login role="pupil" />} exact />
                    <Route path="/employee/:classId" component={RegisterPupil} />
                    <Route path="/divisions/:id/code" component={Code} />
                    <Route path="/departments" component={Schools} exact />
                    <Route path="/departments/create" component={SchoolForm} exact />
                    <Route path="/departments/:id" component={SchoolForm} exact />
                    <Route path="/departments/:id/divisions" component={Classes} exact />
                    <Route
                        path="/departments/:departmentId/divisions/create"
                        component={ClassForm}
                    />
                    <Route path="/divisions/:id" component={ClassForm} exact />
                    <Route path="/divisions/:id/employees" component={Pupils} />
                    <Route path="/statistic/departments" component={StatisticSchools} exact />
                    <Route path="/statistic/departments/:id" component={StatisticClasses} />
                    <Route path="/statistic/divisions/:id" component={StatisticPupils} exact />
                    <Route path="/statistic/employees/:id" component={StatisticPupil} exact />
                    <Route
                        path="/statistic/divisions/:classId/filters"
                        component={StatisticClassesFilters}
                    />
                    <Route
                        path="/statistic/employees/:pupilId/filters"
                        component={StatisticClassesFilters}
                    />
                    <Route path="/employees/:id" component={PupilPage} exact />
                    <Route path="/employees/:id/categories" component={CategoriesPage} />
                    <Route path="/employees/:pupilId/meetings" component={Meetings} exact />
                    <Route path="/employees/:pupilId/meetings/create" component={MeetingForm} />
                    <Route path="/employees/:pupilId/meetings/:meetingId" component={MeetingForm} />
                    <Route path="/riskGroups" component={RiskGroups} exact />
                    <Route path="/meetings" component={Meetings} exact />
                    <Route path="/meetings/departments" component={Schools} exact />
                    <Route path="/meetings/departments/:id/divisions" component={Classes} exact />
                    <Route path="/meetings/divisions/:id/employees" component={Pupils} exact />
                    <Route path="/meetings/:meetingId" component={MeetingForm} />
                    <Route path="/advices" component={AdvicesPage} exact />
                    <Route path="/notes" component={NotesPage} exact />
                    <Route path="/alarms" component={AlarmsPage} exact />
                    <Route path="/instruction" component={InstructionPage} exact />
                    <Route path="/workWithMethods/problems" component={ProblemsPage} exact />
                    <Route path="/workWithMethods/problems/:id" component={ProblemPage} exact />
                    <Route path="/tests" component={TestsPage} exact />
                    <Route path="/tests/info/:id" component={TestsPage} exact />
                    <Route path="/tests/instruction/:id" component={TestsPage} exact />
                    <Route path="/tests/create" component={CreateTestPage} exact />
                    <Route path="/tests/create/:id" component={CreateTestPage} exact />
                    <Route path="/tests/create/:classId/filters" component={CreateTestFilters} />
                    <Route path="/tests/:testId/:conditionId" component={TestPage} />
                    <Route path="/solutions/:id" component={SolutionPage} />
                    <Route path="/settings" component={SettingsPage} exact />
                    <Route path="/settings/security" component={SecurityPage} exact />

                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
