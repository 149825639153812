import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import axios from "axios";

import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { showAlert } from "redux/actions/setAppActions";

/**
 * @api {get} /classes/:id/code qr код
 * @apiGroup psychologists
 * @apiName qr код
 *
 * @apiParam {String} id ID Класса
 *
 * @apiDescription <p>Страница с QR-кодом и ссылкой на страницу регистрации ученика</p>
 * <p>Компонент <code>Code.js</code> <a href="../client/src/pages/Code.js">ссылка на файл</a></p>
 */

export const Code = () => {
    const dispatch = useDispatch();
    const classId = useParams().id;
    const [data, setData] = useState(null);
    const link = `${window.location.origin}/employee/${classId}`;

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`classes/${classId}`);
            setData(data);
        })();
    }, [classId]);

    const copyHandler = () => {
        window.navigator.clipboard.writeText(link);
        dispatch(showAlert({ type: "success", text: "Ссылка скопирована" }));
    };

    return (
        <Layout>
            <div className="page code-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title code-page__title">{data?.school.name}</p>
                </header>

                <div className="page__content code-page__content">
                    <div className="code">
                        <QRCode
                            id="canvas"
                            size={234}
                            renderAs={"svg"}
                            value={link}
                            includeMargin={true}
                        />
                    </div>
                    <p>Отсканируйте QR-Code</p>
                </div>

                <div className="code-page__footer">
                    <p className="code-page__footer-title">{link}</p>
                    <p className="code-page__footer-desc">Ссылка регистрации сотрудника</p>
                    <button onClick={copyHandler} className="code-page__copy-btn" />
                </div>
            </div>
        </Layout>
    );
};
