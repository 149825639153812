import { GET_ADVICES, CREATE_ADVICE, CONFIRM_ADVICE } from "./types";

const initialState = {
    advices: [],
    changedAdvice: null,
};

export const adviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADVICES:
            return { ...state, advices: action.payload };
        case CREATE_ADVICE:
            return { ...state, advices: state.advices.concat([action.payload]) };
        case CONFIRM_ADVICE:
            return { ...state, changedAdvice: action.payload };
        default:
            return state;
    }
};
