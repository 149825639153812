import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { GET_CLASS, CREATE_CLASS, FINISH_CLASS_CREATING } from '../types'


export function getClassINedded(id) {
    return (dispatch, getState) => {
        const state = getState().school

        if (!state.classData || state.classData['_id'] !== id) {
            return dispatch(getClass(id))
        }
    }
}

export function getClass(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/classes/${id}`, { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_CLASS, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}




export function createClass(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())

            const json = await fetch('/api/classes/create', {
                method: 'POST',
                body: JSON.stringify({ ...data }),
                headers: { 'Content-Type': 'application/json', }
            }, getState, dispatch)

            dispatch({ type: CREATE_CLASS, payload: json.newClass })
            dispatch({ type: FINISH_CLASS_CREATING })
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'success', text: 'Класс успешно создан' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}
export function updateClass(classId, data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            await fetch(`/api/classes/${classId}`, {
                method: 'POST',
                body: JSON.stringify({ ...data }),
                headers: { 'Content-Type': 'application/json', }
            }, getState, dispatch)
            dispatch(finishLoading())
            dispatch(getClass(classId))
            dispatch(showAlert({ type: 'success', text: 'Изменения сохранены' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}
