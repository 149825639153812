import React, { memo } from "react";

import image from "static/img/landing/company-img.png";
import loveIcon from "static/img/landing/company-love.svg";

export const LandingCompany = memo(() => {
    return (
        <section className="landing__section" id="landing-company">
            <div className="landing__company">
                <div className="landing__company-block--left">
                    <h2 className="landing__section-title landing__company-title">
                        Компания Лаборатория систем саморазвития нацелена на создание гибких IT систем
                        для потребностей молодого поколения и комфортной цифровой среды, где каждый
                        может получить поддержку, найти подходы к решению своих проблем и быть
                        услышанным.
                    </h2>
                    <p className="landing__company-subtitle">
                        HR.TestU.online - одна из разработанных платформ, отвечающая современным вызовам
                        психологического здоровья сотрудников крупных корпораций и МСБ. Наша платформа
                        делает эффективнее работу Психологов и HR внутри компании, добавляет интерактив
                        в их рабочее взаимодействие с сотрудниками, позволяет пользоваться
                        валидированными методиками по выявлению актуальных проблем работников, а также
                        позволяет руководству вовремя определить кому из сотрудников требуется
                        высказаться, а кого поддержать в сложной ситуации.
                    </p>
                    <div className="landing__company-signature">
                        <img src={loveIcon} alt=""/>
                        <p>С заботой о будущих поколениях,<br/>команда TestU</p>
                    </div>
                </div>

                <div className="landing__section-block-dark landing__company-block--right">
                    <img src={image} alt="" />
                </div>
            </div>
        </section>
    );
});
