import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getPupil } from "redux/actions/setPupilActions";
import { getTestsBiPupilId } from "redux/actions/setTestActions";
import { getNotes } from "redux/actions/setNoteActions";
import { getAdvices } from "redux/actions/setAdviceActions";
import { getMeetings } from "redux/actions/setMeetingActions";
import { useCreateReport } from "hooks/useCreateReport";
import { yearsOldCounter } from "helpers/years";
import { declinations } from "helpers/declinations";
import { Layout } from "components/Layout";
import { Loader } from "components/Loader";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /pupils/:id страница ученика
 * @apiGroup pupils
 * @apiName страница ученика
 *
 * @apiParam {String} id ID ученика
 *
 * @apiDescription <p>Страница ученика</p>
 * <p>Компонент <code>PupilPage.js</code> <a href="../client/src/pages/PupilPage.js">ссылка на файл</a></p>
 */

export const PupilPage = () => {
    const pupilId = useParams().id;
    const dispatch = useDispatch();
    const { notPassedTests, tests } = useSelector((state) => state.test);
    const { pupilData } = useSelector((state) => state.pupil);
    const { advices } = useSelector((state) => state.advice);
    const { meetings } = useSelector((state) => state.meeting);
    const { notes } = useSelector((state) => state.note);
    const { loading } = useSelector((state) => state.app);
    const [isPassedTests, setIsPassedTests] = useState(false);
    const { createReport, isReportLoading } = useCreateReport();

    useEffect(() => {
        dispatch(getTestsBiPupilId(pupilId));
        dispatch(getAdvices(pupilId));
        dispatch(getMeetings(pupilId));
        dispatch(getNotes(pupilId));
        dispatch(getPupil(pupilId));
    }, [dispatch, pupilId]);

    useEffect(() => {
        if (tests.length) {
            const passedTests = tests.filter(({ solutionToken }) => solutionToken);
            setIsPassedTests(!!passedTests.length);
        } else if (isPassedTests) {
            setIsPassedTests(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tests]);

    const createReportHandler = () => {
        createReport(
            `statistic/pupil-report/${pupilId}`,
            `Отчет_${pupilData.surname}_${pupilData.name}.xlsx`
        );
    };

    if (loading || !advices || !pupilData) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className={`page page__small profile-page 'profile-page_pupil'`}>
                <header className="page__header">
                    <BackButton />
                    <p className="page__title profile-page__title">Сотрудник</p>
                </header>

                <div className="profile-page__main">
                    <div className="profile-page__info">
                        <p className="profile-page__name">
                            {pupilData.surname} {pupilData.name} {pupilData?.patronymic}
                        </p>

                        {pupilData.birthday && (
                            <p className="profile-page__birthday">
                                <span>{pupilData.class.name}</span>,{" "}
                                <span>
                                    {yearsOldCounter(pupilData.birthday)}{" "}
                                    {declinations(yearsOldCounter(pupilData.birthday), "year")}
                                </span>
                            </p>
                        )}

                        {pupilData.emailNew && (
                            <p className="profile-page__birthday">
                                <span>Почта: {pupilData.emailNew}</span>,
                            </p>
                        )}

                        {pupilData.telegram && (
                            <p className="profile-page__birthday">
                                <span>Телеграм: {pupilData.telegram}</span>
                            </p>
                        )}
                    </div>
                </div>

                <div className="profile-page__entities">
                    {isPassedTests && (
                        <div
                            className="statistic-reports__btn statistic-reports__btn-pupil-profile"
                            onClick={createReportHandler}
                        >
                            {isReportLoading ? (
                                <p>Отчет загружается...</p>
                            ) : (
                                <p>Сформировать отчет</p>
                            )}
                        </div>
                    )}

                    <Link
                        to={`/employees/${pupilId}/categories`}
                        className={`profile-page__psych-alarm`}
                    >
                        <div>
                            <p>Группы риска</p>
                            <p>Укажите группы риска для этого сотрудника</p>
                        </div>
                        <span>{pupilData.categories.length}</span>
                    </Link>

                    <div className="profile-page__entities-wrapper">
                        <Link
                            to={`/tests?employeeId=${pupilId}&departmentId=${pupilData.class.school}&divisionId=${pupilData.class._id}`}
                            className={`entity ${!!notPassedTests && "entity_test"}`}
                        >
                            <p
                                className={`entity__title ${
                                    !!notPassedTests && "entity__title_white"
                                }`}
                            >
                                Тесты
                            </p>
                            {!notPassedTests && (
                                <p className="entity__description">У сотрудника пока нет тестов</p>
                            )}
                            <span
                                className={`entity__extra ${
                                    !notPassedTests && "entity__extra_pupil-null"
                                }`}
                            >
                                {notPassedTests}
                            </span>
                        </Link>
                        <Link
                            to={`/employees/${pupilId}/meetings`}
                            className={`entity ${"entity_meeting"}`}
                        >
                            <p
                                className={`entity__title 
                            ${"entity__title_white"}`}
                            >
                                Встречи
                            </p>
                            <span
                                className={`entity__extra 
                            ${!meetings.length}`}
                            >
                                {meetings.length}
                            </span>
                        </Link>
                        <Link
                            to={`/advices?employeeId=${pupilId}`}
                            className={`entity ${"entity_advice"}`}
                        >
                            <p className={`entity__title ${"entity__title_white"}`}>Рекомендации</p>
                            <p className={`entity__description  ${"entity__description_white"}`}>
                                Доступно для сотрудника
                            </p>
                            <span className={`entity__extra `}>{advices.length}</span>
                        </Link>
                        <Link to={`/notes?employeeId=${pupilId}`} className="entity entity_note">
                            <p className="entity__title entity__title_white">Заметки</p>
                            <p className="entity__description entity__description_white">
                                Не отображается у сотрудника
                            </p>
                            <span className={`entity__extra`}>{notes.length}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
