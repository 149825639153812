export const declinations = (value, type) => {
    const forms = {
        year: ['год', 'года', 'лет'],
        test: ['тест', 'теста', 'тестов'],
        scale: ['шкала', 'шкалы', 'шкал'],
    }
    const n = Math.abs(value) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) return forms[type][2];
    if (n1 > 1 && n1 < 5) return forms[type][1];
    if (n1 === 1) return forms[type][0];

    return forms[type][2];
};