import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /tests/create/:classId/filters фильтр учеников при отправке теста
 * @apiGroup tests
 * @apiName фильтр учеников при отправке теста
 *
 * @apiParam {String} classId ID класса
 *
 * @apiDescription <p>Страница фильтрации учеников при отправке теста</p>
 * <p>Компонент <code>CreateTestFilters.js</code> <a href="../client/src/pages/CreateTestFilters.js">ссылка на файл</a></p>
 */

export const CreateTestFilters = () => {
    const dispatch = useDispatch();
    const pupilId = useParams().pupilId;
    const [categories, setCategories] = useState([]);
    const { filters } = useSelector((state) => state.createTest);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get("categories");
            setCategories(data.categories);
        })();
    }, []);

    const changeHandler = (e) => {
        if (e.target.name === "years") {
            const pattern = /^[,0-9 ]*$/i;
            const inputValid = pattern.test(e.target.value.replace(/ /g, ""));
            if (!inputValid) return;
        }

        dispatch({
            type: "CREATE_TEST/SET_FILTERS",
            payload: { ...filters, [e.target.name]: e.target.value },
        });
    };

    const categoryHandler = (evt) => {
        const category = filters.category === evt.target.id ? "" : evt.target.id;

        dispatch({
            type: "CREATE_TEST/SET_FILTERS",
            payload: { ...filters, category },
        });
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Фильтры</p>
                </header>
                <div className="page__content">
                    {!pupilId && (
                        <>
                            <h3>Выберите необходимые параметры</h3>
                            <div className="auth-page__input-group auth-page__input-group_row">
                                <div className="input-field">
                                    <input
                                        id="n"
                                        value=""
                                        name="sex"
                                        type="radio"
                                        checked={filters.sex === ""}
                                        onChange={changeHandler}
                                    />
                                    <label htmlFor="n">Все</label>
                                </div>
                                <div className="input-field">
                                    <input
                                        id="m"
                                        name="sex"
                                        value="1"
                                        type="radio"
                                        checked={filters.sex === "1"}
                                        onChange={changeHandler}
                                    />
                                    <label htmlFor="m">Мужской</label>
                                </div>
                                <div className="input-field">
                                    <input
                                        id="f"
                                        type="radio"
                                        name="sex"
                                        value="2"
                                        checked={filters.sex === "2"}
                                        onChange={changeHandler}
                                    />
                                    <label htmlFor="f">Женский</label>
                                </div>
                            </div>
                            <div className="auth-page__input-group">
                                <div className="input-field">
                                    <input
                                        id="surname"
                                        type="text"
                                        name="years"
                                        placeholder="Укажите возраст сотрудников через запятую"
                                        onChange={changeHandler}
                                        value={filters.years}
                                    />
                                    <label htmlFor="years">Возраст</label>
                                </div>
                            </div>
                            <div>
                                <div className="list">
                                    {categories.map((category) => (
                                        <div key={category._id} className="list__checkbox">
                                            <input
                                                type="checkbox"
                                                id={category._id}
                                                value={category._id}
                                                onChange={categoryHandler}
                                                checked={filters.category === category._id}
                                            />
                                            <label htmlFor={category._id}>
                                                <div>
                                                    <p>{category.title}</p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};
