import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const Tools = ({ tools }) => {
    const [isActive, setIsActive] = useState(false)

    if (isActive) {
        return (
            <div className="page tools">
                <div className="tools__items">
                    {tools && tools.map((tool, index) => {
                        return tool.url
                            ? <Link key={index} to={tool.url} className="tools__item">{tool.title}</Link>
                            : <button key={index} onClick={tool.action} className="tools__item">{tool.title}</button>
                    }

                    )}
                </div>
                <button onClick={() => { setIsActive(!isActive) }} className="tools__close-btn" />
            </div>
        )
    }

    return <button onClick={() => { setIsActive(!isActive) }} className="tools__open-btn" />
}