import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import {GET_ADVICES, CREATE_ADVICE, CONFIRM_ADVICE} from '../types'


export function createAdvice(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())

            const json = await fetch('/api/advices/create', {
                method: 'POST',
                body: JSON.stringify({ ...data }),
                headers: { 'Content-Type': 'application/json', }
            }, getState, dispatch)

            dispatch({ type: CREATE_ADVICE, payload: json.advice })
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'success', text: 'Рекомендация успешно создана' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function getAdvices(pupilId) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/advices${pupilId ? `?pupilId=${pupilId}` : ''}`,
                { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_ADVICES, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

// export function readAdvices() {
//     return async (dispatch, getState) => {
//         try {
//             dispatch(startLoading())
//             await fetch(`/api/advices/read`,
//                 {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json', }
//                 }, getState, dispatch)
//             dispatch(finishLoading())
//
//         } catch (e) {
//             dispatch(finishLoading())
//             dispatch(showAlert({ type: 'error', text: e.message }))
//         }
//     }
// }

export function readAdvice(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/advices/read/${id}`,
              {
                  method: 'PUT',
                  headers: { 'Content-Type': 'application/json', }
              }, getState, dispatch)

            dispatch({ type: CONFIRM_ADVICE, payload: json })
            dispatch(finishLoading())
        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}