import { UPDATE_USER } from './types'


const initialState = {
    role: null,
    user: null,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return { ...state, user: action.payload }
        case 'AUTH/SET_USER':
            const { user, role } = action.payload
            return { ...state, user, role }
        default:
            return state
    }
}