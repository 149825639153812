import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showAlert } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

const initialState = { city: "", name: "" };

/**
 * @api {get} /schools/create создание школы
 * @apiGroup schools and classes
 * @apiName создание школы
 *
 * @apiDescription <p>Страница создания школы</p>
 * <p>Компонент <code>SchoolForm.js</code> <a href="../client/src/pages/schools/SchoolForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /schools/:id редактирования школы
 * @apiGroup schools and classes
 * @apiName редактирования школы
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница редактирования школы</p>
 * <p>Компонент <code>SchoolForm.js</code> <a href="../client/src/pages/schools/SchoolForm.js">ссылка на файл</a></p>
 */

export const SchoolForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const [school, setSchool] = useState(null);
    const [form, setForm] = useState(initialState);

    useEffect(() => {
        if (id) {
            (async () => {
                const { data } = await axios.get(`schools/${id}`);
                setSchool(data);
                setForm({
                    ...initialState,
                    city: data.city,
                    name: data.name,
                });
            })();
        }
    }, [id]);

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            if (school) {
                await axios.post(`schools/${school._id}`, form);
            } else {
                await axios.post("schools/create", form);
            }

            history.goBack();
        } catch (err) {
            dispatch(
                showAlert({
                    type: "error",
                    text: "Ошибка создания департамента",
                })
            );
        }
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Департаменты</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{school ? "Изменить департамент" : "Добавить департамент"}</h3>
                    <div className="input-field">
                        <input
                            id="city"
                            type="text"
                            name="city"
                            placeholder="Город"
                            onChange={changeHandler}
                            value={form.city}
                        />
                        <label htmlFor="city">Город</label>
                    </div>
                    <div className="input-field">
                        <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Название"
                            onChange={changeHandler}
                            value={form.name}
                        />
                        <label htmlFor="name">Название</label>
                    </div>
                </div>

                <button onClick={submit} disabled={!form.city || !form.name} className="main-btn">
                    {school ? "Изменить департамент" : "Добавить департамент"}
                </button>
            </div>
        </Layout>
    );
};
