import { useSelector } from "react-redux";

import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";

/**
 * @api {get} /instruction инструкция
 * @apiGroup profile
 * @apiName инструкция
 *
 * @apiDescription <p>Страница с инструкциями для психологов и учеников</p>
 * <p>Компонент <code>InstructionPage.js</code> <a href="../client/src/pages/InstructionPage.js">ссылка на файл</a></p>
 */

export const InstructionPage = () => {
    const { role } = useSelector((state) => state.auth);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />

                    <p className="page__title">Инструкция</p>
                </header>

                {role === "psych" && <InstructionPsych />}
                {role === "pupil" && <InstructionPupil />}
            </div>
        </Layout>
    );
};

function InstructionPsych() {
    return (
        <div className="instruction-page__text">
            <p>Добрый день, Коллеги!</p>
            <p>
                Вашему вниманию предлагается IT платформа TestU.online , разработанная компанией
                “Лаборатория Систем Саморазвития” на базе Инновационного центра Сколково.
                <br />
                По запросу Вы можете ознакомиться с презентацией, где описана концепция платформы,
                которая поможет качественно быстро и эффективно проводить процесс психодиагностики
                подростков в Вашем учреждении.
            </p>
            <p>
                Мы предлагаем Вам провести массовые и одиночные тестирования в ваших учреждениях,
                посмотреть вывод результатов, рекомендаций после проведения тестирования и
                дополнительного функционала.
            </p>
            <p>
                В базовом решении в комплекс психодиагностики входят 10 методик для выявления
                актуальных проблем сотрудников. С ними вы можете ознакомиться в разделе «Работа с
                методиками» в личном кабинете психолога. Информацию о каждой методике вы можете
                найти на нашем сайте, либо прочитать о них в профильной литературе.
            </p>
            <p>
                Подробные инструкции Вы можете посмотреть по следующей ссылке:
                <a
                    href="https://www.youtube.com/playlist?list=PLYxJa_0Ez7spOU--WUfyAhzmWJhhQQbg7"
                    target="_blank"
                    rel="noreferrer"
                    className="youtube-link"
                >
                    посмотреть видеоинструкцию
                </a>
            </p>
            <p>
                Чтобы начать пользоваться TestU, перейдите по ссылке{" "}
                <a href="https://hr.testu.online/">https://hr.testu.online/</a> . После нажмите на
                желтую кнопку “Вход в систему” по центру экрана. Введите в поля логин и пароль,
                которые были вам предоставлены на этапе регистрации в системе.
            </p>
            <p>
                Когда вы войдете в личный кабинет, вам необходимо добавить свой департамент. Для
                этого нажмите на “Департаменты” и далее нажмите на синюю кнопку “Добавить
                департамент” внизу экрана и внесите данные о потоке, затем нажмите на синюю кнопку
                “Добавить департамент” внизу экрана.
            </p>
            <p>
                Далее нажмите на департамент, который добавили. Добавьте нужные отделы, для этого
                нажмите на синюю кнопку “Добавить отдел” внизу экрана, заполните данные об группе и
                нажмите на синюю кнопку “Добавить отдел” внизу экрана. Вы можете называть
                «Департамент» и «отдел» любые другие группы респондентов, так как это не более чем
                иерархия для систематизации тестирования.
            </p>
            <p>
                После для каждого отдела добавьте сотрудников, для этого нажмите на кнопку с черным
                значком QR кода справа от группы.
            </p>
            <p>
                Вам откроется большой QR-код, для регистрации сотрудников в системе - достаточно
                показать сотрудникам QR код со своего компьютера или вывести его на электронную
                доску через проектор для сканирования через камеру мобильного телефона.
            </p>
            <p>
                При регистрации сотрудник должен заполнить все поля; по придуманному паролю и логину
                в будущем для данного сотрудника осуществляется вход на платформу, поэтому эти
                данные необходимо запомнить.
            </p>
            <p>
                Также вы можете прислать ссылку своим сотрудникам по почте (она находится на
                странице с большим QR кодом конкретной группы), чтобы они зарегистрировались на
                сайте удаленно.
            </p>
            <p>
                После того, как сотрудники зарегистрируются в системе – они смогут пройти тесты,
                которые вы им назначите. Назначить тест можно либо через раздел «Работа с
                методиками», либо через синий кружок внизу экрана на главной странице в личном
                кабинете.
            </p>
            <p>
                Вы сможете увидеть результаты через раздел «Результаты психодиагностики», где вы
                можете последовательно выбрать группу респондентов для которой хотите видеть
                результат, включая фильтрацию по конкретному тесту, шкале и уровню.
            </p>
            <p>
                Результаты в данный момент можно смотреть по каждому сотруднику в отдельности,
                поэтому наша рекомендация – это настраивать фильтры по уровням чтобы выделять список
                сотрудников в группе риска, а затем на странице каждого сотрудника отщелкивать
                галочку «Группа риска» в соответствующем разделе.
            </p>
            <p>
                Так же вы можете сгенерировать отчёт по сотруднику, нажав на фиолетовую кнопку
                «Сформировать отчёт». После нажатия вы скачаете эксель файл с отчётом внутри.
            </p>
            <p>
                У сотрудников существует функция «Хочу поговорить», кнопка расположена в личном
                кабинете сотрудников на главной странице. При нажатии на неё – вы как специалист
                получите уведомление у себя в личном кабинете, что определенный сотрудник хотел бы
                что-то обсудить. Этот функционал не предназначен для экстренной помощи, но в рамках
                плановых запросов от сотрудников позволяет им легко заявить о своем запросе на
                беседу с психологом.
            </p>
            <p>
                «Блок заметки» - вы можете осуществлять записи по каждому сотрудников, если это
                необходимо. Данный блок не доступен для сотрудников. Рекомендуем в данном блоке в
                первой заметке записать логин и пароль сотрудника, особенно у сотрудников из группы
                риска, чтобы в случае потери доступа вы могли подсказать его.
            </p>
            <p>
                «Блок рекомендации» - вы можете осуществлять записи в формате рекомендации по
                каждому сотруднику, если это необходимо. Данный блок доступен для сотрудников.
            </p>

            <h4>В блоке «Настройки» просьба не менять ваш логин и почту.</h4>

            <p>
                По любым вопросам технического характера, сбоев вы можете обращаться по почте{" "}
                <a href="mailto:help@testu.online">help@testu.online</a> или по телефону:{" "}
                <a href="tel:+79605217575">89605217575</a> , Валерий
            </p>
            <p>
                По вопросам предложений развития платформы и важных для вас необходимых функций вы
                можете обращаться по почте –{" "}
                <a href="mailto:school@testu.online">school@testu.online</a>
            </p>

            <h4>Информация по формированию отчетов:</h4>
            <p style={{ marginLeft: 20 }}>
                1. Генерация отчёта по классу по всем методикам (без каких-либо фильтров):
            </p>
            <p>
                По каждой методике создаётся лист. В нём идёт список сотрудников, которым когда-либо
                назначалась соответствующая методика. Результаты каждого сотрудника идут по
                последней назначенной методике. Если сотрудник не прошёл последнюю назначенную
                методику, то в его результатах напишет "Отсутствует". Дальше по сотрудникам, у
                которых есть результаты, считается среднее и строятся диаграммы.
            </p>
            <p style={{ marginLeft: 20 }}>
                2. Генерация по отчёту по отделу по выбранной методике (указана дата и методика)
            </p>
            <p>
                По методике создаётся лист. Для каждого сотрудника, которому в соответствующую дату
                была назначена методика выводится результат по этой методике. Если сотрудник не
                прошёл методику, то в его результатах напишет "Отсутствует". Дальше по сотрудникам,
                у которых есть результаты, считается среднее и строятся диаграммы.
            </p>
            <p style={{ marginLeft: 20 }}>3. Генерация отчёта по сотруднику по всем методикам:</p>
            <p>
                Для сотрудника берутся все методики, пишется результат по крайнему решению для
                каждой методики. Если сотрудник никогда не решал методику, то она в отчёте не
                пишется. Для каждой методики выводится среднее по отделу. Среднее значение считается
                по крайним результатам каждого сотрудника из отдела, по которому генерируем отчёт
                (результат сотрудника, по которому генерируем тоже учитывается). Ниже строятся
                диаграммы по результатам.
            </p>
            <p style={{ marginLeft: 20 }}>
                4. Генерация отчёта по одному сотруднику по одной методике:
            </p>
            <p>
                Для сотрудника пишется результат по крайнему решению методики. Для методики
                выводится среднее по отделу. Среднее значение считается по крайним результатам
                каждого сотрудника из отдела, по которому генерируем отчёт (результат сотрудника, по
                которому генерируем тоже учитывается). Ниже строятся диаграммы по результатам.
            </p>
        </div>
    );
}

function InstructionPupil() {
    return (
        <div className="instruction-page__text">
            <p>Добрый день!</p>
            <p>
                Вашему вниманию предлагается продукт, который позволяет быстро и эффективно
                проводить различного типа анкетирования.
            </p>
            <p>Советуем вам запомнить логин и пароль, придуманные вами для входа в систему.</p>
            <p>В блоке «Тесты» будут появляться тесты, которые назначит вам ваш психолог.</p>
            <p>
                Если у вас есть запрос на связь с психологом – вы можете нажать кнопку «Хочу
                поговорить», данный функционал предназначен не для экстренного решения проблем, а
                для того, чтобы у вас была возможность безопасно заявить о наличии запроса на
                разговор.
            </p>
            <p>
                В блоке «Рекомендации» ваш специалист сможет формировать вам индивидуальные
                рекомендации для вас, в этом разделе вы сможете с ними ознакомиться.
            </p>
            <p>
                Мы работаем над улучшением продукта и добавлением полезных функций. Будем рады
                обратной связи, если у вас есть идеи как можно улучшить наш продукт: добавить
                интересные контент по теме, методики по профориентации или что-то дополнительное.
            </p>
            <p>Просьба не менять логин в разделе «Безопасность и вход»</p>
            <p>
                По любым вопросам технического характера, сбоев вы можете обращаться по почте{" "}
                <a href="mailto:help@testu.online">help@testu.online</a>
            </p>
            <p>
                По вопросам предложений развития платформы и важных для вас необходимых функций вы
                можете обращаться по почте –{" "}
                <a href="mailto:school@testu.online">school@testu.online</a>
            </p>
        </div>
    );
}
