import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

/**
 * @api {get} /settings/security безопасность
 * @apiGroup profile
 * @apiName безопасность
 *
 * @apiDescription <p>Страница безопасности</p>
 * <p>Компонент <code>SecurityPage.js</code> <a href="../client/src/pages/SecurityPage.js">ссылка на файл</a></p>
 */

export const SecurityPage = () => {
    const { role } = useSelector((state) => state.auth);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                </header>
                <Link to={`/${role}/lose`} className="main-btn">
                    Восстановить пароль
                </Link>
            </div>
        </Layout>
    );
};
