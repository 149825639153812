import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { getCookie, setCookie } from "helpers/cookies";
import { returnRightWordForYears, yearsOldCounter } from "helpers/years";
import { Tools } from "components/Tools";
import { Histories } from "components/Histories";
import { Layout } from "components/Layout";
import { PupilProfile } from "components/profile/PupilProfile";
import { PsychProfile } from "components/profile/PsychProfile";
import "../static/scss/main.scss";

/**
 * @api {get} /dashboard профиль пользователя
 * @apiGroup profile
 * @apiName профиль пользователя
 *
 * @apiDescription <p>Страница профиля психолога или ученика</p>
 * <p>Компонент <code>ProfilePage.js</code> <a href="../client/src/pages/ProfilePage.js">ссылка на файл</a></p>
 */

export const ProfilePage = () => {
    const { role, user } = useSelector((state) => state.auth);
    const { loading } = useSelector((state) => state.app);
    const [isShowedCookies, setIsShowedCookies] = useState(true);

    useEffect(() => {
        if (getCookie("watchedCookies")) {
            setIsShowedCookies(false);
        }
    }, []);

    function cookiesHandler() {
        setCookie("watchedCookies", "true", "30");
        setIsShowedCookies(false);
    }

    return (
        <Layout>
            <div
                className={`page page__small profile-page ${
                    role === "pupil" ? "profile-page_pupil" : "profile-page_psych"
                }`}
            >
                {role === "psych" && (
                    <Tools
                        tools={[
                            { url: "/meetings/departments", title: "Назначить встречу" },
                            { url: "/tests/create", title: "Отправить тест" },
                        ]}
                    />
                )}

                <header className="page__header">
                    <Link
                        to="/settings"
                        className="icon-btn page__icon-btn page__icon-btn_right icon-btn_settings"
                    ></Link>
                    <p className="page__title profile-page__title">
                        {role === "pupil" ? "Сотрудник" : "HR / Психолог"}
                    </p>
                </header>

                <div className="profile-page__main">
                    <div className="profile-page__info">
                        <p className="profile-page__name">
                            {user?.surname} {user?.name} {user?.patronymic}
                        </p>
                        {user?.birthday && (
                            <p className="profile-page__birthday">
                                {yearsOldCounter(user?.birthday)}{" "}
                                {returnRightWordForYears(yearsOldCounter(user?.birthday))}
                            </p>
                        )}
                        {role === "pupil" && (
                            <>
                                <p className="profile-page__birthday">
                                    Ваш HR / психолог: {user?.psychName} {user?.psychPatronymic}
                                </p>
                                {user?.psychSchedule && (
                                    <p className="profile-page__birthday">
                                        График работы психолога:
                                        <br />
                                        <span className="profile-page__schedule">
                                            {user?.psychSchedule}
                                        </span>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                    <div className="profile-page__contacts">
                        {/*<button className="profile-page__btn">Привязать мессенджер</button>*/}
                    </div>
                </div>

                {isShowedCookies && (
                    <div className="cookies">
                        <div className="page">
                            <h3>Об использовании файлов cookie</h3>
                            <p>
                                Для предоставления персонализированного содержимого и анализа
                                использования нашего сайта на нем используются файлы cookie.
                                Пользователь вправе в любое время самостоятельно изменить настройки
                                приема файлов cookie в настройках своего браузера или отключить их
                                полностью
                            </p>
                            <button
                                onClick={() => {
                                    cookiesHandler();
                                }}
                            >
                                Понятно, спасибо
                            </button>
                        </div>
                    </div>
                )}

                {role === "pupil" && <PupilProfile user={user} loading={loading} />}

                {role === "psych" && (
                    <>
                        <PsychProfile loading={loading} />
                        <Histories />
                    </>
                )}
            </div>
        </Layout>
    );
};
