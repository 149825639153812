import React, { memo } from "react";

import innovativeProducts from "static/docs/innovative-products.pdf";

export const LandingDocuments = memo(() => {
    return (
        <section className="landing__section" id="landing-documents">
            <div className="landing__documents">
                <h2 className="landing__section-title landing__documents-title">
                    Документы и гарантии
                </h2>
                <p className="landing__documents-subtitle">
                    TestU.online разработана и успешно апробирована в ряде субъектов РФ в части
                    школьной психодиагностики. Разработанная платформа и методология прошли строгую
                    сертификацию и экспертизу. В настоящий момент Авторы продукта адаптировали
                    решение под задачи HR стресс-менеджмента.
                </p>

                <div className="landing__documents-content">
                    <a
                        href="https://drive.google.com/file/d/1MWrnra2q6WmD2OE40OrHvao4VGQ52QY2/view"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="landing__documents-content-block">
                            <span>Экспертное заключение психологического факультета МГУ</span>
                        </div>
                    </a>

                    <a
                        href="https://smarteka.com/practices/testuonline-platforma-avtomatizacii-skolnoj-psihodiagnostiki"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="landing__documents-content-block">
                            <span>
                                Практика входит в перечень Агентства Стратегических Инициатив на
                                платформе Смартека
                            </span>
                        </div>
                    </a>

                    <a href={innovativeProducts} download>
                        <div className="landing__documents-content-block">
                            <span>
                                Включение в перечень инновационных продуктов, рекомендованных к
                                Государственным закупкам
                            </span>
                        </div>
                    </a>
                    <a
                        href="https://reestr.digital.gov.ru/reestr/1149392/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="landing__documents-content-block">
                            <span>
                                Продукт TestU.online включен в реестр Российского программного
                                обеспечения
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
});
