import React, { useCallback, useEffect, useState } from 'react';

import { TestButton } from './TestButton';

export const RangeAnswers = ({
    answers,
    question,
    confirmBtnHandler,
    setAnswer,
    isConfirmPossible,
    isBackBtnActive,
    backBtnHandler
}) => {
    const min = parseInt(question.answers[0].code);
    const [value, setValue] = useState(0);
    const [max, setMax] = useState(0);

    useEffect(() => {
        if (question) {
            setMax(parseInt(question.answers[question.answers.length - 1].code));
        }
    }, [question]);

    useEffect(() => {
        if (question && answers[0]) {
            const findValue = question.answers.find(({ id }) => id === answers[0]);
            const savedValue = findValue ? parseInt(findValue.code) : min

            setValue(savedValue);
        } else {
            setValue(min);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers]);

    const handleChange = (e) => {
        const answer = question?.answers.find(({ code }) => code === e.target.value);

        if (answer) {
            setAnswer(answer.id);
        }
    };

    const clickHandler = (e) => {
        const inputValue = e.target.getAttribute('data-value');
        const answer = question?.answers.find(({ code }) => code === inputValue);

        if (answer) {
            setAnswer(answer.id);
        }
    };

    const useDotes = useCallback(() => {
        const dotes = [];
        let number = min;
        for (var i = 0; i < question.answers.length; i++) {
            dotes.push(
                <li style={i >= value ? { background: `#DEEBF9` } : null} key={i}>
                    <div
                        data-value={number}
                        onClick={(e) => clickHandler(e)}
                        className="walk-range__clickHandler"
                    ></div>
                </li>
            );
            number++;
        }
        return dotes;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question, value]);

    useEffect(() => {
        var isFirefox = typeof InstallTrigger !== 'undefined';
        if (isFirefox) {
            let progress = document.getElementsByClassName('walk-range__progress')[0];
            let dots = document.getElementsByClassName('walk-range__dotes')[0];
            if (progress) {
                progress.style.display = 'none';
            }
            if (dots) {
                dots.style.zIndex = '0';
            }
        }
    }, []);

    return (
        <div className="walk-range-wrapper">
            <div className="walk-range">
                <span className="walk-range__min">{min}</span>
                <span className="walk-range__max">{max}</span>
                <ul className="walk-range__dotes">{useDotes()}</ul>
                <input
                    onChange={handleChange}
                    className="walk-range__slider"
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                />
                <div
                    className="walk-range__progress"
                    style={{ width: `${((value - min) * 100) / (max - min)}%` }}
                />
            </div>

            <div className="walk-range-wrapper__btns-block">
                <TestButton
                    isActive={isBackBtnActive}
                    onClickHandler={backBtnHandler}
                    type="back"
                />
                <TestButton isActive={isConfirmPossible} onClickHandler={confirmBtnHandler} />
            </div>
            <p>Потяните ползунок чтобы выбрать подходящий ответ</p>
        </div>
    );
};
