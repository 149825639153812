import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { Layout } from "components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "components/BackButton";
import { SET_STATISTIC_FILTER_CATEGORY, SET_STATISTIC_FILTERS } from "redux/types";

/**
 * @api {get} /statistic/classes/:classId/filters фильтр статистики класса
 * @apiGroup statistic
 * @apiName фильтр статистики класса
 *
 * @apiParam {String} classId ID класса
 *
 * @apiDescription <p>Страница фильтрации статистики класса</p>
 * <p>Компонент <code>StatisticClassesFilters.js</code> <a href="../client/src/pages/statistic/StatisticClassesFilters.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /statistic/pupils/:pupilId/filters фильтр статистики ученика
 * @apiGroup statistic
 * @apiName фильтр статистики ученика
 *
 * @apiParam {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница фильтрации статистики ученика</p>
 * <p>Компонент <code>StatisticClassesFilters.js</code> <a href="../client/src/pages/statistic/StatisticClassesFilters.js">ссылка на файл</a></p>
 */

export const StatisticClassesFilters = () => {
    const dispatch = useDispatch();
    const classId = useParams().classId;
    const pupilId = useParams().pupilId;
    const { filters } = useSelector((state) => state.statistic);
    const [conditions, setConditions] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [dates, setDates] = useState([]);
    const [search, setSearch] = useState("");
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get("categories");
            setCategories(data.categories);
        })();
    }, []);

    useEffect(() => {
        const arr = [];

        if (classId) {
            arr.push(`classId=${classId}`);
        }

        if (pupilId) {
            arr.push(`pupilId=${pupilId}`);
        }

        if (filters.date) {
            arr.push(`date=${filters.date}`);
        }

        (async () => {
            const { data } = await axios.get(`tests/conditions-filter?${arr.join("&")}`);

            setConditions(data);
        })();
    }, [filters.date, classId, pupilId, dispatch]);

    useEffect(() => {
        const arr = [];

        if (classId) {
            arr.push(`classId=${classId}`);
        }

        if (pupilId) {
            arr.push(`pupilId=${pupilId}`);
        }

        (async () => {
            const { data } = await axios.get(`tests/dates-filter?${arr.join("&")}`);
            setDates(data);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = conditions.filter(
            (t) => t?.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
        setFiltered(data);
    }, [conditions, search]);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "years") {
            const pattern = /^[,0-9 ]*$/i;
            const inputValid = pattern.test(value.replace(/ /g, ""));
            if (!inputValid) return;
        }

        dispatch({
            type: SET_STATISTIC_FILTERS,
            payload: { name, value },
        });
    };

    const categoryHandler = (id, categoryTitle) => {
        const category = filters.category === id ? "" : id;
        const categoryName = category ? categoryTitle : "";

        dispatch({
            type: SET_STATISTIC_FILTER_CATEGORY,
            payload: { category, categoryName },
        });
    };

    const conditionHandler = (e) => {
        if (e.currentTarget.id === filters.condition) {
            let newPayload = filters;
            newPayload.condition = "";
            newPayload.scale = "";
            newPayload.level = 0;
            return dispatch({
                type: "STATISTIC/SET_FILTERS",
                payload: newPayload,
            });
        }
        let newPayload = filters;
        newPayload.condition = e.currentTarget.id;
        newPayload.scale = "";
        newPayload.level = 0;
        dispatch({
            type: "STATISTIC/SET_FILTERS",
            payload: newPayload,
        });
    };

    return (
        <Layout>
            <div className="page page__statistic-filters">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Фильтры</p>
                </header>
                <div className="page__content">
                    {!pupilId && (
                        <>
                            <h3>Выберите необходимые параметры</h3>
                            <div className="auth-page__input-group auth-page__input-group_row">
                                <div className="input-field">
                                    <input
                                        id="n"
                                        value=""
                                        name="sex"
                                        type="radio"
                                        checked={filters.sex === ""}
                                        onChange={changeHandler}
                                    />
                                    <label htmlFor="n">Все</label>
                                </div>
                                <div className="input-field">
                                    <input
                                        id="m"
                                        name="sex"
                                        value="1"
                                        type="radio"
                                        checked={filters.sex === "1"}
                                        onChange={changeHandler}
                                    />
                                    <label htmlFor="m">Мужской</label>
                                </div>
                                <div className="input-field">
                                    <input
                                        id="f"
                                        type="radio"
                                        name="sex"
                                        value="2"
                                        checked={filters.sex === "2"}
                                        onChange={changeHandler}
                                    />
                                    <label htmlFor="f">Женский</label>
                                </div>
                            </div>
                            <div className="auth-page__input-group">
                                <div className="input-field">
                                    <input
                                        id="surname"
                                        type="text"
                                        name="years"
                                        placeholder="Укажите возраст сотрудников через запятую"
                                        onChange={changeHandler}
                                        value={filters.years}
                                    />
                                    <label htmlFor="years">Возраст</label>
                                </div>
                            </div>
                            <div>
                                <div className="list">
                                    {categories.map((category) => (
                                        <div key={category._id} className="list__checkbox">
                                            <input
                                                type="checkbox"
                                                id={category._id}
                                                value={category._id}
                                                onChange={() => {
                                                    categoryHandler(category._id, category.title);
                                                }}
                                                checked={filters.category === category._id}
                                            />
                                            <label htmlFor={category._id}>
                                                <div>
                                                    <p>{category.title}</p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    <div>
                        <h3>Выберите условие и дату теста</h3>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                            }}
                        >
                            <div className="search">
                                <FaSearch
                                    style={{
                                        marginRight: "8px",
                                        color: "rgba(18, 36, 67, 0.6)",
                                    }}
                                />
                                <input
                                    id="search"
                                    type="text"
                                    name="search"
                                    placeholder="Поиск"
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                            </div>
                            <div>
                                <select
                                    name="date"
                                    style={{
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                        letterSpacing: ".02em",
                                        color: "#122443",
                                        marginLeft: "1rem",
                                    }}
                                    value={filters.date}
                                    onChange={changeHandler}
                                >
                                    <option value="">Не выбрано</option>
                                    {dates.map((date) => (
                                        <option key={date} value={date}>
                                            {date}
                                            {/*{moment(date.date).format('DD MMM YYYY')}*/}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="list">
                            {filtered.map((condition) => (
                                <div
                                    id={condition._id}
                                    key={condition._id}
                                    onClick={conditionHandler}
                                    className="list__item"
                                    style={
                                        filters.condition === condition._id
                                            ? {
                                                  color: "#fff",
                                                  background:
                                                      "linear-gradient(to left, #00c6ff, #0072ff)",
                                              }
                                            : {}
                                    }
                                >
                                    <p>{condition.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
